// Main Container widths
// We need these values to add space left and right of items which don't have 100% width

// Breakpoints
$tablet: 768px;
$desktop: 1024px;
$desktop-large: 1200px;
$wide-screen: 1366px;
$ultra-wide-screen: 1600px;

$navigation-background-switch: 1460px;
$event-list-grid-switch: 640px;
$image-gallery-switch: 640px;
$image-description-switch: 640px;

// Margins and paddings
$container-margin: 20px; // Used for side margins, paddings

$ce-margin-vertical: 60px; // used for top and bottom margin
$ce-margin-vertical-tablet: 60px; // used for top and bottom margin
$ce-margin-vertical-desktop: 60px; // used for top and bottom margin
$ce-margin-vertical-desktop-large: 60px; // used for top and bottom margin
$ce-margin-vertical-widescreen: 60px; // used for top and bottom margin

$ce-padding-vertical: 50px; // Padding e.g. for Items with background colors

// Tablet view, should be at least less than $tablet + 2 * $container-margin
$container-width-tablet: $tablet - 2 * $container-margin;

// Desktop view, should be at least less than $desktop + 2 * $container-margin
$container-width-desktop: $desktop - 2 * $container-margin;

// Desktop large view, should be at least less than $desktop-large + 2 * $container-margin
$container-width-desktop-large: $desktop-large - 2 * $container-margin;

// Widescreen view, should be at least less than $wide-screen + 2 * $container-margin
$container-width-widescreen: $wide-screen - 2 * $container-margin;


// Color variables
$color-white: #fff;
$color-black: #000;
$text-gray: #46484f;
$text-dark-gray: #828282;
$red-color: #cc0800;
$gray-light: #ccc;
$background-red: #a30600;
$background-gray: #dbdde0;
$background-blue: #0674d4;

$tooltip-background: #FFF9E6;
$tooltip-border: #B9B9B9;
$gray-background-product-list: #f1f4ff;
$blue-landing-page: #0674d4;
$gray-darker: #46484F;
$gray-transparent: rgba(#454242, 0.5);

// Fonts
$font-head: 'Open Sans', sans-serif;
$font-pt-serif: "PT Serif", serif;
$font-size: 12px;
$font-size-items: 16px;




