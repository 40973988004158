@charset "UTF-8";
@font-face {
  font-family: "project";
  src: url(/assets/fonts/project/project.eot);
  src: url(/assets/fonts/project/project.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/project/project.woff) format("woff"), url(/assets/fonts/project/project.ttf) format("truetype"), url(/assets/fonts/project/project.svg#project) format("svg");
  font-weight: 400;
  font-style: normal; }

/* line 14, workfiles/project/css/main.scss */
[data-icon]:before {
  font-family: "project" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 27, workfiles/project/css/main.scss */
[class^="project-"]:before, [class*=" project-"]:before {
  font-family: "project" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 39, workfiles/project/css/main.scss */
.project-cancel:before {
  content: "a"; }

/* line 43, workfiles/project/css/main.scss */
.project-check:before {
  content: "b"; }

/* line 47, workfiles/project/css/main.scss */
.project-facebook:before {
  content: "c"; }

/* line 51, workfiles/project/css/main.scss */
.project-google:before {
  content: "d"; }

/* line 55, workfiles/project/css/main.scss */
.project-minus:before {
  content: "e"; }

/* line 59, workfiles/project/css/main.scss */
.project-plus:before {
  content: "f"; }

/* line 63, workfiles/project/css/main.scss */
.project-twiter:before {
  content: "g"; }

/* line 67, workfiles/project/css/main.scss */
.project-uncheck:before {
  content: "h"; }

/* line 71, workfiles/project/css/main.scss */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

/* line 86, workfiles/project/css/main.scss */
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }

/* line 94, workfiles/project/css/main.scss */
.slick-list:focus {
  outline: none; }

/* line 98, workfiles/project/css/main.scss */
.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

/* line 103, workfiles/project/css/main.scss */
.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* line 108, workfiles/project/css/main.scss */
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }

/* line 115, workfiles/project/css/main.scss */
.slick-track:before, .slick-track:after {
  content: "";
  display: table; }

/* line 120, workfiles/project/css/main.scss */
.slick-track:after {
  clear: both; }

/* line 124, workfiles/project/css/main.scss */
.slick-loading .slick-track {
  visibility: hidden; }

/* line 128, workfiles/project/css/main.scss */
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }

/* line 135, workfiles/project/css/main.scss */
[dir="rtl"] .slick-slide {
  float: right; }

/* line 139, workfiles/project/css/main.scss */
.slick-slide img {
  display: block; }

/* line 143, workfiles/project/css/main.scss */
.slick-slide.slick-loading img {
  display: none; }

/* line 147, workfiles/project/css/main.scss */
.slick-slide.dragging img {
  pointer-events: none; }

/* line 151, workfiles/project/css/main.scss */
.slick-initialized .slick-slide {
  display: block; }

/* line 155, workfiles/project/css/main.scss */
.slick-loading .slick-slide {
  visibility: hidden; }

/* line 159, workfiles/project/css/main.scss */
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

/* line 165, workfiles/project/css/main.scss */
.slick-arrow.slick-hidden {
  display: none; }

/* line 169, workfiles/project/css/main.scss */
.slick-loading .slick-list {
  background: #fff url(./../img/slick/ajax-loader.gif) center center no-repeat; }

@font-face {
  font-family: "slick";
  src: url(/assets/fonts/slick/slick.eot);
  src: url(/assets/fonts/slick/slick.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/slick/slick.woff) format("woff"), url(/assets/fonts/slick/slick.ttf) format("truetype"), url(/assets/fonts/slick/slick.svg#slick) format("svg");
  font-weight: 400;
  font-style: normal; }

/* line 181, workfiles/project/css/main.scss */
.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none; }

/* line 198, workfiles/project/css/main.scss */
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent; }

/* line 204, workfiles/project/css/main.scss */
.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1; }

/* line 208, workfiles/project/css/main.scss */
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25; }

/* line 212, workfiles/project/css/main.scss */
.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 222, workfiles/project/css/main.scss */
.slick-prev {
  left: 30px;
  z-index: 1000; }

/* line 227, workfiles/project/css/main.scss */
[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px; }

/* line 232, workfiles/project/css/main.scss */
.slick-prev:before {
  content: "←"; }

/* line 236, workfiles/project/css/main.scss */
[dir="rtl"] .slick-prev:before {
  content: "→"; }

/* line 240, workfiles/project/css/main.scss */
.slick-next {
  right: 30px;
  z-index: 1000; }

/* line 245, workfiles/project/css/main.scss */
[dir="rtl"] .slick-next {
  left: -25px;
  right: auto; }

/* line 250, workfiles/project/css/main.scss */
.slick-next:before {
  content: "→"; }

/* line 254, workfiles/project/css/main.scss */
[dir="rtl"] .slick-next:before {
  content: "←"; }

/* line 258, workfiles/project/css/main.scss */
.slick-slider {
  margin-bottom: 30px; }

/* line 262, workfiles/project/css/main.scss */
.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }

/* line 272, workfiles/project/css/main.scss */
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

/* line 282, workfiles/project/css/main.scss */
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer; }

/* line 296, workfiles/project/css/main.scss */
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none; }

/* line 300, workfiles/project/css/main.scss */
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1; }

/* line 304, workfiles/project/css/main.scss */
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: #000;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 321, workfiles/project/css/main.scss */
.slick-dots li.slick-active button:before {
  color: #000;
  opacity: .75; }

/* line 326, workfiles/project/css/main.scss */
.margin-none-top {
  margin-top: 0; }

/* line 330, workfiles/project/css/main.scss */
.margin-none-top h2 {
  margin-top: 0; }

/* line 334, workfiles/project/css/main.scss */
.margin-small-top {
  margin-top: 15px; }

/* line 338, workfiles/project/css/main.scss */
.margin-middle-top {
  margin-top: 40px; }

/* line 342, workfiles/project/css/main.scss */
.margin-large-top {
  margin-top: 60px; }

/* line 346, workfiles/project/css/main.scss */
.margin-none-right {
  margin-right: 0; }

/* line 350, workfiles/project/css/main.scss */
.margin-small-right {
  margin-right: 15px; }

/* line 354, workfiles/project/css/main.scss */
.margin-middle-right {
  margin-right: 40px; }

/* line 358, workfiles/project/css/main.scss */
.margin-large-right {
  margin-right: 60px; }

/* line 362, workfiles/project/css/main.scss */
.margin-none-bottom {
  margin-bottom: 0; }

/* line 366, workfiles/project/css/main.scss */
.margin-small-bottom {
  margin-bottom: 15px; }

/* line 370, workfiles/project/css/main.scss */
.margin-middle-bottom {
  margin-bottom: 40px; }

/* line 374, workfiles/project/css/main.scss */
.margin-large-bottom {
  margin-bottom: 60px; }

/* line 378, workfiles/project/css/main.scss */
.margin-none-left {
  margin-left: 0; }

/* line 382, workfiles/project/css/main.scss */
.margin-small-left {
  margin-left: 15px; }

/* line 386, workfiles/project/css/main.scss */
.margin-middle-left {
  margin-left: 40px; }

/* line 390, workfiles/project/css/main.scss */
.margin-large-left {
  margin-left: 60px; }

/* line 394, workfiles/project/css/main.scss */
.padding-none-top {
  padding-top: 0; }

/* line 398, workfiles/project/css/main.scss */
.padding-small-top {
  padding-top: 15px; }

/* line 402, workfiles/project/css/main.scss */
.padding-middle-top {
  padding-top: 40px; }

/* line 406, workfiles/project/css/main.scss */
.padding-large-top {
  padding-top: 60px; }

/* line 410, workfiles/project/css/main.scss */
.padding-none-right {
  padding-right: 0; }

/* line 414, workfiles/project/css/main.scss */
.padding-small-right {
  padding-right: 15px; }

/* line 418, workfiles/project/css/main.scss */
.padding-middle-right {
  padding-right: 40px; }

/* line 422, workfiles/project/css/main.scss */
.padding-large-right {
  padding-right: 60px; }

/* line 426, workfiles/project/css/main.scss */
.padding-none-bottom {
  padding-bottom: 0; }

/* line 430, workfiles/project/css/main.scss */
.padding-small-bottom {
  padding-bottom: 15px; }

/* line 434, workfiles/project/css/main.scss */
.padding-middle-bottom {
  padding-bottom: 40px; }

/* line 438, workfiles/project/css/main.scss */
.padding-large-bottom {
  padding-bottom: 60px; }

/* line 442, workfiles/project/css/main.scss */
.padding-none-left {
  padding-left: 0; }

/* line 446, workfiles/project/css/main.scss */
.padding-small-left {
  padding-left: 15px; }

/* line 450, workfiles/project/css/main.scss */
.padding-middle-left {
  padding-left: 40px; }

/* line 454, workfiles/project/css/main.scss */
.padding-large-left {
  padding-left: 60px; }

/* line 458, workfiles/project/css/main.scss */
.darkgrey {
  background-color: #38393D; }

/* line 462, workfiles/project/css/main.scss */
.lightgrey {
  background-color: #F1F4FF; }

/* line 466, workfiles/project/css/main.scss */
.light-bulb {
  background: url(../img/project/icon/light_bulb.png) no-repeat #fff;
  background-size: 40px;
  background-position: top 20px center; }

/* line 472, workfiles/project/css/main.scss */
.blue-key {
  background: url(../img/project/icon/blue_key.png) no-repeat #fff;
  background-size: 40px;
  background-position: center top 20px; }

/* line 478, workfiles/project/css/main.scss */
.download-icon {
  background: url(../img/project/icon/download_icon.png) no-repeat #fff;
  background-size: 40px;
  background-position: center top 20px; }

/* line 484, workfiles/project/css/main.scss */
.experts-icon {
  background: url(../img/project/icon/experts_icon@2x.png) no-repeat #fff;
  background-size: 40px;
  background-position: left top; }

/* line 490, workfiles/project/css/main.scss */
.globe-icon {
  background: url(../img/project/icon/globe_icon@2x.png) no-repeat #fff;
  background-size: 40px;
  background-position: left top; }

/* line 496, workfiles/project/css/main.scss */
.rocket-icon {
  background: url(../img/project/icon/rocket_icon@2x.png) no-repeat #fff;
  background-size: 40px;
  background-position: left top; }

/* line 502, workfiles/project/css/main.scss */
.reduce-costs-icon {
  background: url(../img/project/icon/reduce_costs_icon@2x.png) no-repeat #fff;
  background-size: 40px;
  background-position: left top; }

/* line 508, workfiles/project/css/main.scss */
.arrows-left-right {
  background: url(../img/project/icon/arrows_left_right.png) no-repeat #fff;
  background-size: 40px;
  background-position: left top; }

/* line 514, workfiles/project/css/main.scss */
.triumph-gate {
  background: url(../img/project/icon/german_menagement_icon@2x.png) no-repeat #fff;
  background-size: 40px;
  background-position: center top 20px; }

/* line 520, workfiles/project/css/main.scss */
.int-platfotm {
  background: url(../img/project/icon/int_platform_icon@2x.png) no-repeat #fff;
  background-size: 68px;
  background-position: center top 20px; }

/* line 526, workfiles/project/css/main.scss */
.suit-case {
  background: url(../img/project/icon/suit_case_icon.png) no-repeat #fff;
  background-size: 40px;
  background-position: center top 20px; }

/* line 532, workfiles/project/css/main.scss */
.invest-icon {
  background: url(../img/project/icon/invest-icon@2x.png) no-repeat #fff;
  background-size: 35px;
  background-position: center top 25px; }

/* line 538, workfiles/project/css/main.scss */
.export-icon {
  background: url(../img/project/icon/export-icon@2x.png) no-repeat #fff;
  background-size: 30px 40px;
  background-position: center top 20px; }

/* line 544, workfiles/project/css/main.scss */
.supply-icon {
  background: url(../img/project/icon/supply-icon@2x.png) no-repeat #fff;
  background-size: 35px 25px;
  background-position: center top 35px; }

/* line 550, workfiles/project/css/main.scss */
.navbar-slim {
  padding: 0 0 7px !important;
  box-shadow: 0 1px 15px -6px #686868; }

/* line 555, workfiles/project/css/main.scss */
html {
  position: relative;
  min-height: 100%; }

/* line 560, workfiles/project/css/main.scss */
body {
  padding-top: 96px;
  margin: 0;
  font-weight: 300; }

@media screen and (max-width: 768px) {
  /* line 567, workfiles/project/css/main.scss */
  body {
    margin: 0; } }

@media screen and (max-width: 480px) {
  /* line 575, workfiles/project/css/main.scss */
  body {
    padding-top: 85px; }
  /* line 579, workfiles/project/css/main.scss */
  body h1, body h2 {
    font-size: 38px;
    margin-bottom: 10px; }
  /* line 584, workfiles/project/css/main.scss */
  body h3 {
    font-size: 28px !important;
    line-height: 1.3 !important; } }

/* line 590, workfiles/project/css/main.scss */
body p {
  margin: 0 0 20px; }

/* line 594, workfiles/project/css/main.scss */
body .container {
  max-width: 1100px; }

/* line 598, workfiles/project/css/main.scss */
body .container a {
  text-decoration: underline;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 604, workfiles/project/css/main.scss */
body .container a.btn-link {
  text-decoration: none; }

/* line 608, workfiles/project/css/main.scss */
body.page2, body.page1, body.page29 {
  min-height: 665px;
  width: 100%; }

/* line 613, workfiles/project/css/main.scss */
body header a, body footer a {
  text-decoration: none !important; }

@media screen and (max-width: 769px) {
  /* line 618, workfiles/project/css/main.scss */
  body.page35 {
    overflow-x: hidden; }
  /* line 621, workfiles/project/css/main.scss */
  body.page17 {
    overflow-x: hidden; } }

/* line 626, workfiles/project/css/main.scss */
body.page35 .main-navigation .linkitem-li-35,
body.page17 .main-navigation .linkitem-li-17 {
  background: #DBDDE0; }

/* line 631, workfiles/project/css/main.scss */
body.page35 .main-navigation .linkitem-li-35 a:hover,
body.page35 .main-navigation .linkitem-li-35 a:active,
body.page17 .main-navigation .linkitem-li-17 a:hover,
body.page17 .main-navigation .linkitem-li-17 a:active {
  background: #c3c3c3 !important; }

/* line 638, workfiles/project/css/main.scss */
body.page35 .main-navigation .linkitem-li-35 a,
body.page17 .main-navigation .linkitem-li-17 a {
  color: #46484f !important; }

/* line 643, workfiles/project/css/main.scss */
.navbar-default {
  padding: 20px 0;
  box-shadow: 0 1px 15px -6px #686868;
  margin-bottom: 0;
  -webkit-transition: all .6s ease;
  transition: all .6s ease; }

@media screen and (max-width: 992px) {
  /* line 652, workfiles/project/css/main.scss */
  .navbar-default {
    margin-bottom: 20px; } }

@media screen and (max-width: 480px) {
  /* line 658, workfiles/project/css/main.scss */
  .navbar-default {
    padding: 10px 0; } }

/* line 663, workfiles/project/css/main.scss */
body.page2 .navbar-default,
body.page1 .navbar-default,
body.page29 .navbar-default,
body.page85 .navbar-default {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out; }

/* line 673, workfiles/project/css/main.scss */
.navbar-default .main-navigation {
  display: table;
  margin-left: 40px !important;
  float: left;
  transition: all .4s ease; }

@media screen and (max-width: 991px) {
  /* line 681, workfiles/project/css/main.scss */
  .navbar-default .main-navigation {
    width: 100%;
    float: none;
    margin: 0 auto !important; } }

/* line 688, workfiles/project/css/main.scss */
.navbar-header .navbar-toggle, .navbar-toggle.collapsed {
  border: none;
  margin-top: 12px; }

/* line 693, workfiles/project/css/main.scss */
.navbar-toggle.collapsed:hover, .navbar-toggle.collapsed:active, .navbar-toggle.collapsed:focus {
  background: none; }

/* line 697, workfiles/project/css/main.scss */
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ed1c24; }

/* BECOME A PARTNER BUTTON -- SHOW ON HIGHER RESOLUTIONS */
/* line 703, workfiles/project/css/main.scss */
.navbar-default .navbar-partner {
  float: right;
  margin: 2px 10px -5px 0;
  transition: all .4s ease; }

/* line 709, workfiles/project/css/main.scss */
.navbar-partner .show-desktop {
  margin-right: 30px; }

/* line 713, workfiles/project/css/main.scss */
.navbar-partner li > a {
  padding: 10px 0 0; }

/* BECOME A PARTNER BUTTON -- SHOW ON LOWER RESOLUTIONS */
/* line 719, workfiles/project/css/main.scss */
.navbar-partner .show-mobile {
  position: relative;
  display: none;
  border: none;
  padding: 0;
  background: #cc0800;
  margin-left: -15px;
  margin-top: 12px;
  transition: all .4s ease;
  border-radius: 2px !important;
  box-shadow: none !important; }

@media screen and (max-width: 1220px) {
  /* line 733, workfiles/project/css/main.scss */
  .navbar-partner .show-mobile {
    display: inline-block; }
  /* line 736, workfiles/project/css/main.scss */
  .navbar-partner .show-desktop {
    display: none; } }

@media screen and (max-width: 1190px) {
  /* line 742, workfiles/project/css/main.scss */
  .navbar-nav.main-navigation li:not(.linkitem-li-92) > a,
  .navbar-nav.main-navigation li:not(.linkitem-li-35) > a,
  .navbar-nav.main-navigation li:not(.linkitem-li-17) > a {
    padding: 10px; }
  /* line 747, workfiles/project/css/main.scss */
  .main-navigation .linkitem-li-35,
  .main-navigation .linkitem-li-92,
  .main-navigation .linkitem-li-17 {
    margin-top: 10px !important; } }

@media screen and (max-width: 991px) {
  /* line 755, workfiles/project/css/main.scss */
  .show-mobile {
    position: absolute !important;
    width: 80%;
    text-align: center;
    left: 15%;
    margin: 0 20px;
    top: 7px; } }

/* line 765, workfiles/project/css/main.scss */
.navbar-partner .show-mobile:hover, .navbar-partner .show-mobile:focus, .navbar-partner .show-mobile:active {
  background-color: #a30600; }

/* line 769, workfiles/project/css/main.scss */
.navbar-partner .show-mobile:hover a:after,
.navbar-partner .show-mobile:focus a:after,
.navbar-partner .show-mobile:active a:after {
  border-left: 10px solid #a30600; }

/* line 775, workfiles/project/css/main.scss */
.navbar-partner .show-mobile a {
  color: #fff !important;
  font-weight: 300;
  padding: 2px 7px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  text-transform: none !important; }

/* line 784, workfiles/project/css/main.scss */
.navbar-partner .show-mobile a:after {
  display: none; }

/* line 788, workfiles/project/css/main.scss */
.red-link {
  color: #CC0000 !important; }

/* line 792, workfiles/project/css/main.scss */
.navbar-default .navbar-partner span.become-partner {
  padding: 10px 20px;
  color: #46484f;
  margin: -20px 0 0;
  display: inline-block;
  font-size: 11px;
  line-height: 1.4;
  text-transform: none;
  transition: all .6s ease; }

/* line 803, workfiles/project/css/main.scss */
body.page1 .navbar-default .navbar-partner span.become-partner,
body.page85 .navbar-default .navbar-partner span.become-partner,
body.page29 .navbar-default .navbar-partner span.become-partner {
  color: #f4f4f4; }

/* line 809, workfiles/project/css/main.scss */
span.become-partner:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: url("/assets/img/project/icon/banner-arrow-left@2x.png") no-repeat;
  background-size: 20px;
  left: -8px;
  top: 6px; }

/* line 820, workfiles/project/css/main.scss */
span.become-partner:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: url("/assets/img/project/icon/banner-arrow-right@2x.png") no-repeat;
  background-size: 20px;
  right: -25px;
  top: 6px; }

/* line 831, workfiles/project/css/main.scss */
.banner-light:before {
  background: url("/assets/img/project/icon/banner-arrow-left-w@2x.png") no-repeat !important;
  background-size: 20px !important;
  width: 40px;
  height: 40px;
  transition: all .4s ease; }

/* line 839, workfiles/project/css/main.scss */
.banner-light:after {
  background: url("/assets/img/project/icon/banner-arrow-right-w@2x.png") no-repeat !important;
  background-size: 20px !important;
  width: 40px;
  height: 40px;
  transition: all .4s ease; }

/* line 847, workfiles/project/css/main.scss */
.navbar-default .navbar-collapse.collapse {
  border: none;
  position: relative;
  box-shadow: none;
  margin-left: 180px; }

@media screen and (max-width: 991px) {
  /* line 855, workfiles/project/css/main.scss */
  .navbar-default .navbar-collapse.collapse,
  .navbar-default .navbar-collapse.collapse.in {
    padding-bottom: 20px;
    margin: 0 -15px !important; } }

/* line 862, workfiles/project/css/main.scss */
.navbar-default ul li {
  margin: 6px 0 0; }

/* LANGUAGES AND LOGIN BEGIN */
/* line 869, workfiles/project/css/main.scss */
.languages-and-login {
  margin-top: 15px; }

/*
.languages-and-login {
	position: relative;
	margin-top: -2px;
	transition: all .6s ease;
	width:100px;
}

.languages-and-login span {
	position: absolute;
    top: 0;
    right: 8px;
    font-size: 12px;
    color: #999;
	transition: all .6s ease;
}
*/
/* line 891, workfiles/project/css/main.scss */
body.page1 .languages-and-login a,
body.page29 .languages-and-login a,
body.page85 .languages-and-login a,
body.page1 .languages-and-login span,
body.page85 .languages-and-login span,
body.page29 .languages-and-login span {
  color: #fff; }

@media screen and (min-width: 992px) {
  /* line 901, workfiles/project/css/main.scss */
  .languages-and-login {
    font-size: 16px;
    text-transform: uppercase; } }

@media screen and (max-width: 991px) {
  /* line 908, workfiles/project/css/main.scss */
  .languages-and-login {
    margin-top: 70px;
    text-align: center; }
  /* line 912, workfiles/project/css/main.scss */
  .languages-and-login span {
    top: 12px;
    color: #d00 !important;
    margin-top: 50px;
    display: block;
    color: #d00 !important; }
  /* line 919, workfiles/project/css/main.scss */
  .languages-and-login span a {
    color: #d00 !important; }
  /* line 923, workfiles/project/css/main.scss */
  body.page85 .languages-and-login,
  body.parent85 .languages-and-login,
  body.page85 .languages-and-login,
  body.parent85 .languages-and-login {
    margin-top: 0 !important; } }

/* line 931, workfiles/project/css/main.scss */
.languages-and-login span > a {
  color: #999; }

/* line 935, workfiles/project/css/main.scss */
.navbar-default .navbar-languages {
  margin-top: 12px; }

/* line 939, workfiles/project/css/main.scss */
.navbar-default .navbar-languages li a {
  padding: 4px !important;
  margin: 0 4px; }

/* line 944, workfiles/project/css/main.scss */
body.page1 .navbar-default .navbar-languages li a,
body.page85 .navbar-default .navbar-languages li a,
body.page29 .navbar-default .navbar-languages li a {
  color: #fff; }

/* line 950, workfiles/project/css/main.scss */
.navbar-default .navbar-languages li.active a {
  color: #0b76ce; }

/* line 954, workfiles/project/css/main.scss */
.bluetext {
  color: #0b76ce; }

/* line 958, workfiles/project/css/main.scss */
span.underlined {
  text-decoration: underline; }

/* LOG IN - LOG OUT */
/* line 964, workfiles/project/css/main.scss */
.login-section .lead {
  color: #cc0800 !important; }

/* line 968, workfiles/project/css/main.scss */
.form-horizontal .control-label {
  text-align: left !important; }

/* line 972, workfiles/project/css/main.scss */
.click-container, .click-container2, .click-container3 {
  display: none; }

/* line 976, workfiles/project/css/main.scss */
.click-container.show-input,
.click-container2.show-input,
.click-container3.show-input {
  display: block;
  margin-top: 10px; }

/* line 983, workfiles/project/css/main.scss */
.indented-right {
  margin-left: 40px;
  margin-bottom: 10px; }

/* line 988, workfiles/project/css/main.scss */
.checkboxes-wrap {
  padding-left: 20px; }

/* line 992, workfiles/project/css/main.scss */
.checkboxes-wrap strong {
  font-size: 16px;
  font-weight: normal; }

/* line 997, workfiles/project/css/main.scss */
.col-xs-12.uploads {
  padding-left: 0; }

/* line 1001, workfiles/project/css/main.scss */
.account-information .form-control {
  margin-bottom: 20px; }

/* line 1005, workfiles/project/css/main.scss */
.small-description {
  font-family: "Open Sans", sans-serif;
  line-height: 1.4;
  font-size: 12px;
  clear: both;
  display: block;
  line-height: 1.4; }

/* line 1014, workfiles/project/css/main.scss */
.login-dropdown hr {
  margin-top: 0; }

/* line 1018, workfiles/project/css/main.scss */
.login-dropdown .control-label {
  padding-left: 0;
  line-height: 1.3;
  padding-top: 10px; }

/* line 1024, workfiles/project/css/main.scss */
.login-dropdown .trigger-hover:hover,
.login-dropdown .trigger-hover:active,
.login-dropdown .trigger-hover:focus {
  background-color: transparent; }

/* line 1030, workfiles/project/css/main.scss */
.login-dropdown .active-tab,
.login-dropdown .trigger-hover:hover,
.login-dropdown .trigger-hover:focus,
.login-dropdown .trigger-hover:active {
  background-color: transparent; }

/* line 1037, workfiles/project/css/main.scss */
.default-label-small {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  cursor: pointer !important; }

/* .checkbox label:last-of-type { margin: 0px !important; } */
/* line 1046, workfiles/project/css/main.scss */
.error-border {
  border: 1px solid #cc0800; }

/* line 1050, workfiles/project/css/main.scss */
.error-message {
  background: #cc0800;
  padding: 10px 25px;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
  display: inline-block; }

/* line 1059, workfiles/project/css/main.scss */
.subheadline {
  font-size: 16px; }

/* line 1063, workfiles/project/css/main.scss */
i.checkbox-label {
  display: inline-block;
  margin-top: 10px; }

/* SELECT2 PLUGIN */
/* line 1071, workfiles/project/css/main.scss */
.select2.select2-container {
  width: 340px !important; }

/* line 1075, workfiles/project/css/main.scss */
.select2-results__option {
  margin: 0 !important; }

/* line 1079, workfiles/project/css/main.scss */
.select2-search__field {
  display: none; }

/* line 1083, workfiles/project/css/main.scss */
.select2-container--default .select2-selection--single {
  background-color: #e2e3e7;
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 40px; }

/* line 1090, workfiles/project/css/main.scss */
.select2-selection__rendered {
  padding-top: 5px;
  padding-left: 20px !important; }

/* line 1095, workfiles/project/css/main.scss */
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 8px;
  right: 8px; }

/* TOOLTIPSTER PLUGIN */
/*
.tooltip {
	width: 100%;
	height: 100%;
    top: 0;
}
*/
/* line 1111, workfiles/project/css/main.scss */
.default-label-small {
  position: relative; }

/* _________________________*/
/*
.flag-bckg {
	background: url('/assets/img/project/flag-germany.png') no-repeat;
	background-size: 50%;
	background-position: center center;
	text-indent: -99999px;
	width: 35px;
	height: 30px;
}

.flag-bckg.last-li {
	background: url('/assets/img/project/flag-united-kingdom.png') no-repeat;
	background-size: 50%;
	background-position: center center;
}
*/
/*
.navbar-default .navbar-languages li.active a:after {
	content:"";
	position:absolute;
	top:100%;
	left:50%;
	-webkit-transform:translateX(-50%);
	transform:translateX(-50%);
	width:0;
	height:0;
	border-left:7px solid transparent;
	border-right:7px solid transparent;
	border-top:7px solid #0b76ce;
}
*/
/*

.navbar-default .navbar-languages li.active a:hover {
	color:$color-white;
}
*/
@media screen and (max-width: 991px) {
  /* line 1157, workfiles/project/css/main.scss */
  .navbar-default .navbar-languages li {
    display: inline-block; } }

/* line 1162, workfiles/project/css/main.scss */
.navbar > .container .navbar-brand {
  margin-left: 0; }

/* line 1166, workfiles/project/css/main.scss */
.logo {
  display: block;
  padding: 0;
  margin-top: 5px;
  max-width: 180px; }

@media screen and (max-width: 768px) {
  /* line 1174, workfiles/project/css/main.scss */
  .logo {
    margin-left: 15px !important; } }

@media screen and (max-width: 480px) {
  /* line 1180, workfiles/project/css/main.scss */
  .logo {
    max-width: 140px; } }

/* line 1185, workfiles/project/css/main.scss */
.logo img {
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s; }

/* line 1191, workfiles/project/css/main.scss */
.header-image-wrapper {
  height: 320px;
  overflow: hidden;
  margin-top: -5px; }

/* line 1197, workfiles/project/css/main.scss */
body.page1 .header-image-wrapper,
body.page2 .header-image-wrapper,
body.page85 .header-image-wrapper,
body.page29 .header-image-wrapper {
  height: auto;
  margin-top: -100px; }

@media only screen and (max-width: 1366px) {
  /* line 1206, workfiles/project/css/main.scss */
  .header-image-wrapper {
    height: 200px; } }

@media only screen and (max-width: 800px) {
  /* line 1212, workfiles/project/css/main.scss */
  .header-image-wrapper {
    height: 160px;
    margin-top: -10px; } }

@media only screen and (max-width: 480px) {
  /* line 1219, workfiles/project/css/main.scss */
  .header-image-wrapper {
    height: 80px; } }

/* line 1224, workfiles/project/css/main.scss */
.header-image-wrapper .headline {
  position: absolute; }

/* line 1228, workfiles/project/css/main.scss */
.header-image-wrapper .headline h2 {
  color: #fff;
  min-width: 450px;
  margin: 0;
  font-size: 64px;
  padding: 120px 30px 20px 30px;
  text-shadow: 0 0 100px #000; }

@media screen and (max-width: 1366px) {
  /* line 1239, workfiles/project/css/main.scss */
  .header-image-wrapper .headline h2 {
    padding: 70px 0 20px 30px;
    min-width: 380px; } }

@media screen and (max-width: 1024px) {
  /* line 1246, workfiles/project/css/main.scss */
  .header-image-wrapper .headline h2 {
    padding: 55px 0 20px 30px;
    min-width: 360px; } }

@media screen and (max-width: 768px) {
  /* line 1253, workfiles/project/css/main.scss */
  .header-image-wrapper .headline h2 {
    padding: 40px 30px 20px 30px;
    min-width: 320px; } }

@media screen and (max-width: 420px) {
  /* line 1260, workfiles/project/css/main.scss */
  .header-image-wrapper .headline h2 {
    padding: 20px 0 20px 15px;
    min-width: 250px;
    font-size: 27px; } }

/* line 1267, workfiles/project/css/main.scss */
body.page2 .main-navigation,
body.page1 .main-navigation,
body.page29 .main-navigation,
body.page85 .main-navigation {
  position: relative; }

/* line 1274, workfiles/project/css/main.scss */
body.page2 .main-navigation li.active a,
body.page1 .main-navigation li.active a,
body.page29 .main-navigation li.active a,
body.page85 .main-navigation li.active a {
  color: #fff;
  text-decoration: underline !important; }

/* line 1282, workfiles/project/css/main.scss */
body.page2 .main-navigation li.active a:hover,
body.page2 .main-navigation li.active a:active,
body.page2 .main-navigation li.active a:focus,
body.page1 .main-navigation li.active a:hover,
body.page1 .main-navigation li.active a:active,
body.page1 .main-navigation li.active a:focus,
body.page85 .main-navigation li.active a:hover,
body.page85 .main-navigation li.active a:active,
body.page85 .main-navigation li.active a:focus,
body.page29 .main-navigation li.active a:hover,
body.page29 .main-navigation li.active a:active,
body.page29 .main-navigation li.active a:focus {
  color: #fff; }

/* line 1297, workfiles/project/css/main.scss */
body.page2 .main-navigation li > a,
body.page1 .main-navigation li > a,
body.page29 .main-navigation li > a,
body.page85 .main-navigation li > a {
  color: #fff;
  font-weight: 100;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out; }

/* line 1307, workfiles/project/css/main.scss */
body.page1 .main-navigation li > a:hover,
body.page2 .main-navigation li > a:hover,
body.page29 .main-navigation li:not(.linkitem-li-35) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-92) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-35) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-17) > a:hover {
  text-decoration: underline !important;
  color: #fff; }

@media screen and (max-width: 992px) {
  /* line 1318, workfiles/project/css/main.scss */
  body.page2 .navbar-collapse,
  body.page1 .navbar-collapse,
  body.page29 .navbar-collapse,
  body.page85 .navbar-collapse {
    background-color: #fff; }
  /* line 1325, workfiles/project/css/main.scss */
  body.page2 .navbar-collapse .navbar-nav li > a,
  body.page1 .navbar-collapse .navbar-nav li > a,
  body.page29 .navbar-collapse .navbar-nav li > a,
  body.page85 .navbar-collapse .navbar-nav li > a {
    color: #46484f; }
  /* line 1332, workfiles/project/css/main.scss */
  body.page1 .navbar-collapse .navbar-nav li > a:hover,
  body.page1 .navbar-collapse .navbar-nav li > a:focus,
  body.page29 .navbar-collapse .navbar-nav li > a:hover,
  body.page29 .navbar-collapse .navbar-nav li > a:focus,
  body.page85 .navbar-collapse .navbar-nav li > a:hover,
  body.page85 .navbar-collapse .navbar-nav li > a:focus {
    color: #0b76ce; } }

/* line 1343, workfiles/project/css/main.scss */
.homepage-welcome-box {
  margin-top: 140px; }

/* line 1347, workfiles/project/css/main.scss */
.homepage-welcome-box h1, .homepage-welcome-box h2, .homepage-welcome-box h3, .homepage-welcome-box p {
  color: #fff; }

/* line 1351, workfiles/project/css/main.scss */
.homepage-welcome-box h3 {
  margin-top: 0; }

/* line 1355, workfiles/project/css/main.scss */
.pointer-down {
  position: relative; }

@media screen and (min-width: 481px) and (max-width: 768px) {
  /* line 1360, workfiles/project/css/main.scss */
  .homeboxes {
    margin-top: 190px; } }

@media screen and (max-width: 480px) {
  /* line 1366, workfiles/project/css/main.scss */
  .homeboxes {
    margin-top: 80px; } }

/* line 1371, workfiles/project/css/main.scss */
.partners-boxes .homebox {
  margin-top: 10%;
  min-height: 290px; }

@media screen and (max-width: 768px) {
  /* line 1377, workfiles/project/css/main.scss */
  .partners-boxes .homebox {
    min-height: 210px;
    margin-top: 6%; } }

/* line 1383, workfiles/project/css/main.scss */
.partners-boxes .homebox h3 {
  margin-top: 0; }

/* line 1387, workfiles/project/css/main.scss */
.partners-word {
  overflow: hidden;
  max-height: 340px;
  text-align: center; }

/* line 1393, workfiles/project/css/main.scss */
.homebox {
  border: 1px solid #4678CD;
  border-radius: 0;
  padding: 55px 30px 20px;
  margin-top: 40%;
  min-height: 350px; }

@media screen and (max-width: 1024px) {
  /* line 1402, workfiles/project/css/main.scss */
  .homebox {
    margin-top: 50px;
    min-height: 388px; } }

@media screen and (max-width: 992px) {
  /* line 1409, workfiles/project/css/main.scss */
  .homebox {
    min-height: 240px; } }

/* line 1414, workfiles/project/css/main.scss */
.homebox h3 {
  margin-top: 20px; }

/* line 1418, workfiles/project/css/main.scss */
.homebox h3 > a {
  color: #46484f;
  text-decoration: none; }

/* line 1423, workfiles/project/css/main.scss */
.homebox h3 > a:hover, .homebox h3 > a:active, .homebox h3 > a:focus {
  text-decoration: underline; }

/* line 1427, workfiles/project/css/main.scss */
body.page29 .homebox {
  min-height: 330px;
  margin-top: 40%; }

@media screen and (max-width: 1024px) {
  /* line 1433, workfiles/project/css/main.scss */
  body.page29 .homebox {
    min-height: 395px; } }

@media screen and (max-width: 769px) {
  /* line 1439, workfiles/project/css/main.scss */
  body.page29 .homebox {
    min-height: initial;
    margin: 0 auto 20px; }
  /* line 1443, workfiles/project/css/main.scss */
  .partners-word {
    max-height: none; } }

/* line 1448, workfiles/project/css/main.scss */
.home-section .btn-link {
  margin-top: 15px; }

/* line 1452, workfiles/project/css/main.scss */
.about-us-section h1 {
  margin-top: 95px; }

/* line 1456, workfiles/project/css/main.scss */
.about-us-section .btn-link {
  margin-bottom: 20px; }

@media screen and (max-width: 1024px) {
  /* line 1461, workfiles/project/css/main.scss */
  .about-us-section h1 {
    margin-top: 110px; } }

@media screen and (max-width: 768px) {
  /* line 1467, workfiles/project/css/main.scss */
  .about-us-section h1 {
    margin-top: 57px; } }

/* line 1472, workfiles/project/css/main.scss */
.blue-link {
  position: relative;
  display: inline-block;
  font-size: 26px;
  text-decoration: none !important;
  color: #46484f;
  font-family: "PT Serif", serif;
  height: 82px;
  padding-left: 155px;
  background: url(/assets/img/project/icon/pointer-big@2x.png) no-repeat;
  background-size: 137px;
  background-position: left 6px; }

@media screen and (max-width: 1024px) {
  /* line 1487, workfiles/project/css/main.scss */
  .blue-link {
    font-size: 24px; } }

/* line 1492, workfiles/project/css/main.scss */
.blue-link > span {
  font-size: 12px;
  color: gray;
  font-family: "Open Sans", sans-serif;
  margin-top: -5px;
  display: block; }

/* line 1500, workfiles/project/css/main.scss */
.blue-link .red-link {
  display: block;
  margin-top: -30px;
  text-decoration: underline; }

@media screen and (max-width: 500px) {
  /* line 1507, workfiles/project/css/main.scss */
  .blue-link {
    background: none;
    padding-left: 0;
    font-size: 22px; }
  /* line 1513, workfiles/project/css/main.scss */
  .blue-link:after, .blue-link:before {
    width: 100% !important;
    left: 0; } }

/* line 1519, workfiles/project/css/main.scss */
.blue-link:hover, .blue-link:focus, .blue-link:active {
  color: #242427; }

/* line 1523, workfiles/project/css/main.scss */
.form-bckg {
  background-color: #dbdde0; }

/* line 1527, workfiles/project/css/main.scss */
.form-group, .checkbox {
  margin-bottom: 20px; }

/* line 1531, workfiles/project/css/main.scss */
.form-group label, .checkbox label {
  font-family: "PT Serif", serif;
  font-size: 16px; }

/* line 1536, workfiles/project/css/main.scss */
.form-group label.powermail_label, .checkbox label.powermail_label {
  padding-left: 0; }

/* line 1540, workfiles/project/css/main.scss */
.form-grey .checkbox label:last-of-type {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 300;
  margin: 10px 0; }

/* line 1547, workfiles/project/css/main.scss */
.radio-small label {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0; }

/* line 1554, workfiles/project/css/main.scss */
.form-control {
  background-color: #e2e3e7;
  height: auto;
  padding: 8px 20px;
  box-shadow: none;
  font-weight: 300;
  border-radius: 3px;
  -webkit-appearance: none; }

@media screen and (max-width: 1024px) {
  /* line 1565, workfiles/project/css/main.scss */
  ul.main-navigation > li {
    display: block;
    text-align: center; } }

/* line 1571, workfiles/project/css/main.scss */
.lead-box p {
  font-size: 18px; }

@media screen and (max-width: 500px) {
  /* line 1576, workfiles/project/css/main.scss */
  .lead-box p {
    font-size: 16px; } }

/* line 1581, workfiles/project/css/main.scss */
.sublead-box h2 {
  font-size: 36px; }

/* IMAGE TEXT SLIDER : BEGINS */
/* line 1587, workfiles/project/css/main.scss */
.image-text-slider {
  margin-top: 70px; }

/* line 1591, workfiles/project/css/main.scss */
.image-text-slider .slider-item {
  padding: 0 60px; }

/* line 1595, workfiles/project/css/main.scss */
.image-text-slider .slider-item img {
  display: block;
  border-radius: 100%;
  margin: 0 auto 10px; }

@media screen and (max-width: 480px) {
  /* line 1602, workfiles/project/css/main.scss */
  .image-text-slider .slider-item img {
    width: 100%;
    height: 100%; }
  /* line 1606, workfiles/project/css/main.scss */
  .image-text-slider .slick-prev {
    margin-left: -20px !important; }
  /* line 1609, workfiles/project/css/main.scss */
  .image-text-slider .slick-next {
    margin-right: -20px !important; }
  /* line 1612, workfiles/project/css/main.scss */
  .image-text-slider .slick-prev, .image-text-slider .slick-next {
    margin-top: -120px !important; } }

/* line 1617, workfiles/project/css/main.scss */
.image-text-slider h4 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 8px; }

/* line 1625, workfiles/project/css/main.scss */
.image-text-slider p {
  font-size: 12px; }

/* line 1629, workfiles/project/css/main.scss */
.image-text-slider .slick-prev, .image-text-slider .slick-next {
  width: 40px;
  height: 52px;
  margin-top: -80px;
  transition: all .3s; }

/* line 1636, workfiles/project/css/main.scss */
.image-text-slider .slick-prev {
  margin-left: 20px; }

/* line 1640, workfiles/project/css/main.scss */
.image-text-slider .slick-next {
  margin-right: 20px; }

@media screen and (max-width: 1024px) {
  /* line 1645, workfiles/project/css/main.scss */
  .image-text-slider .slick-prev {
    margin-left: 10px; }
  /* line 1648, workfiles/project/css/main.scss */
  .image-text-slider .slick-next {
    margin-right: 10px; } }

@media screen and (max-width: 480px) {
  /* line 1654, workfiles/project/css/main.scss */
  .image-text-slider .slick-prev {
    margin-left: 0px; }
  /* line 1657, workfiles/project/css/main.scss */
  .image-text-slider .slick-next {
    margin-right: 0px; } }

/* line 1662, workfiles/project/css/main.scss */
.image-text-slider .slick-prev:before, .image-text-slider .slick-next:before {
  visibility: hidden; }

/* line 1666, workfiles/project/css/main.scss */
.image-text-slider .slick-prev {
  background: url("/assets/img/project/icon/arrow_left_grey@2x.png") no-repeat;
  background-size: 26px;
  background-position: center; }

/* line 1672, workfiles/project/css/main.scss */
.image-text-slider .slick-prev:hover, .image-text-slider .slick-prev:focus, .image-text-slider .slick-prev:active {
  background: url("/assets/img/project/icon/arrow_left_blue@2x.png") no-repeat;
  background-size: 26px;
  background-position: center; }

/* line 1679, workfiles/project/css/main.scss */
.image-text-slider .slick-next {
  background: url("/assets/img/project/icon/arrow_right_grey@2x.png") no-repeat;
  background-size: 26px;
  background-position: center; }

/* line 1685, workfiles/project/css/main.scss */
.image-text-slider .slick-next:hover, .image-text-slider .slick-next:focus, .image-text-slider .slick-next:active {
  background: url("/assets/img/project/icon/arrow_right_blue@2x.png") no-repeat;
  background-size: 26px;
  background-position: center; }

/* line 1691, workfiles/project/css/main.scss */
.image-text-slider .slick-dots {
  display: none !important; }

/* IMAGE TEXT SLIDER : ENDS */
/* line 1697, workfiles/project/css/main.scss */
.partners-map, .partners-map-mobile {
  background: url("/assets/img/project/partners_map.jpg") no-repeat;
  background-size: cover;
  height: 440px; }

@media screen and (max-width: 768px) {
  /* line 1704, workfiles/project/css/main.scss */
  .partners-map {
    background: none;
    height: auto; } }

@media screen and (max-width: 768px) {
  /* line 1711, workfiles/project/css/main.scss */
  .partners-map-mobile {
    margin-top: 20px;
    background-size: 100%;
    height: 200px; } }

/* line 1718, workfiles/project/css/main.scss */
.partners-map h3 {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  display: inline-block; }

@media screen and (max-width: 1680px) {
  /* line 1726, workfiles/project/css/main.scss */
  .partners-map {
    background-position-x: 80%; } }

@media screen and (max-width: 1400px) {
  /* line 1732, workfiles/project/css/main.scss */
  .partners-map {
    background-position-x: 70%; } }

@media screen and (max-width: 1220px) {
  /* line 1738, workfiles/project/css/main.scss */
  .partners-map {
    background-position-x: 50%; } }

@media screen and (max-width: 1024px) {
  /* line 1744, workfiles/project/css/main.scss */
  .partners-map {
    height: 346px; }
  /* line 1747, workfiles/project/css/main.scss */
  .partners-map h3 {
    margin-top: 0 !important;
    margin-bottom: 10px; }
  /* line 1751, workfiles/project/css/main.scss */
  .partners-map p {
    font-size: 12px !important; } }

/* line 1756, workfiles/project/css/main.scss */
.partners-map h3 {
  margin-top: 20px; }

/* line 1760, workfiles/project/css/main.scss */
.partners-map p {
  font-size: 13px;
  color: gray; }

/* line 1765, workfiles/project/css/main.scss */
ul {
  list-style: none;
  margin: 0 0 20px;
  padding-left: 30px; }

/* line 1771, workfiles/project/css/main.scss */
ul > li {
  margin: 3px 0 10px;
  position: relative; }

/*ul > li:before {
	content:"";
	position:absolute;
	width:8px;
	height:8px;
	background-color:#0961aa;
	border:1px solid #0961aa;
	left:-20px;
	border-radius:50px;
	top:7px;
}*/
/*.page54 ul > li:before {*/
/*content: "";*/
/*position: absolute;*/
/*width: 7px;*/
/*height: 7px;*/
/*background-color: #0b76ce;*/
/*border: 1px solid #0b76ce;*/
/*left: -17px;*/
/*border-radius: 50px;*/
/*top: 9px;*/
/*}*/
/* line 1800, workfiles/project/css/main.scss */
ul.list-important > li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4; }

/* line 1806, workfiles/project/css/main.scss */
ul.list-important > li:before {
  background-color: #0b76ce; }

/* line 1810, workfiles/project/css/main.scss */
header ul li:before, footer ul li:before {
  display: none; }

/* line 1814, workfiles/project/css/main.scss */
.link-red {
  display: inline-block;
  color: #C00;
  text-decoration: underline;
  margin-bottom: 12px; }

/* line 1821, workfiles/project/css/main.scss */
.link-red:hover {
  color: red; }

/* line 1825, workfiles/project/css/main.scss */
.text-center h2, .text-center h3, .text-center p {
  text-align: center; }

/* line 1829, workfiles/project/css/main.scss */
.newsletter-box h4 {
  margin-top: 0;
  color: #484A50;
  font-weight: 700; }

/* line 1835, workfiles/project/css/main.scss */
.newsletter-container {
  padding: 20px 150px;
  border-radius: 0px; }

@media screen and (max-width: 1024px) {
  /* line 1841, workfiles/project/css/main.scss */
  .newsletter-container {
    padding: 20px 100px; } }

@media screen and (max-width: 500px) {
  /* line 1847, workfiles/project/css/main.scss */
  .newsletter-container {
    padding: 20px 30px; } }

@media screen and (max-width: 500px) {
  /* line 1853, workfiles/project/css/main.scss */
  .newsletter-container .btn-link {
    padding: 8px 20px; } }

@media screen and (max-width: 1034px) {
  /* line 1859, workfiles/project/css/main.scss */
  .btn-link:after {
    display: none; } }

/* line 1864, workfiles/project/css/main.scss */
.newsletter-container .btn-link {
  padding: 8px 30px; }

/* line 1868, workfiles/project/css/main.scss */
.newsletter-box button.btn-link {
  background-color: #cc0800;
  padding: 2px 20px;
  font-size: 13px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 1876, workfiles/project/css/main.scss */
.newsletter-box button.btn-link:hover {
  background-color: #a30600; }

/* line 1880, workfiles/project/css/main.scss */
.newsletter-box button.btn-link:after {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 9px solid #cc0800;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 1888, workfiles/project/css/main.scss */
.newsletter-box button.btn-link:hover:after {
  border-left: 9px solid #a30600; }

/* line 1892, workfiles/project/css/main.scss */
.form-grey {
  margin-top: 60px; }

/* line 1896, workfiles/project/css/main.scss */
.newsletter-info, .form-grey {
  background: url(/assets/img/project/formular_pattern.png) repeat;
  padding: 20px 40px; }

/* line 1901, workfiles/project/css/main.scss */
.newsletter-info p, .form-grey p {
  font-size: 13px; }

/* line 1905, workfiles/project/css/main.scss */
.newsletter-info input:not(.powermail_submit),
.newsletter-info textarea,
.form-grey input:not(.powermail_submit),
.form-grey textarea {
  background-color: #fff;
  border: none;
  border-radius: 0;
  resize: none; }

/* line 1915, workfiles/project/css/main.scss */
textarea {
  resize: vertical; }

/* line 1919, workfiles/project/css/main.scss */
.newsletter-info {
  padding: 30px 40px 20px; }

/* line 1923, workfiles/project/css/main.scss */
.newsletter-info .input-group {
  margin: 20px 0; }

/* line 1927, workfiles/project/css/main.scss */
.newsletter-info .form-control {
  font-size: 13px;
  padding: 2px 15px; }

/* INTERVIEW BEGIN */
/* line 1934, workfiles/project/css/main.scss */
.ineast-interview .col-md-7 {
  margin-top: 35px; }

/* line 1939, workfiles/project/css/main.scss */
.interview-qoute h4 {
  color: #1e7ace;
  margin-top: 115px; }

@media screen and (max-width: 500px) {
  /* line 1945, workfiles/project/css/main.scss */
  .ineast-interview img {
    display: table;
    height: 100%;
    width: 100%; }
  /* line 1950, workfiles/project/css/main.scss */
  .interview-qoute h4 {
    margin-top: 40px; } }

/* RESUME BEGIN */
/* line 1958, workfiles/project/css/main.scss */
.resume-text {
  margin-top: 0px; }

/* line 1962, workfiles/project/css/main.scss */
.resume-achievements h4 {
  padding-left: 15px; }

/* line 1966, workfiles/project/css/main.scss */
.newsletter-box .input-group {
  width: 90%; }

/* line 1970, workfiles/project/css/main.scss */
.newsletter-box .btn-link {
  padding: 8px 30px; }

/* line 1974, workfiles/project/css/main.scss */
.newsletter-box .btn-link:after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

/* line 1980, workfiles/project/css/main.scss */
.row-border-bottom {
  padding: 0 0 15px;
  border-bottom: 1px solid #ccc; }

/* line 1985, workfiles/project/css/main.scss */
.row-border-top {
  padding: 0;
  border-top: 1px solid #ccc; }

/* line 1990, workfiles/project/css/main.scss */
.text-with-logo-right {
  margin-top: 20px; }

/* line 1994, workfiles/project/css/main.scss */
.text-with-logo-right h5 {
  margin-bottom: 5px;
  font-weight: 600; }

/* line 1999, workfiles/project/css/main.scss */
.text-with-logo-right .logo-wrap img {
  width: 210px;
  height: 150px;
  margin-top: 15px; }

/* line 2005, workfiles/project/css/main.scss */
.resources-contact-link h4 {
  font-size: 17px; }

@media screen and (max-width: 768px) {
  /* line 2010, workfiles/project/css/main.scss */
  .text-with-logo-right .logo-wrap img {
    margin-top: 45px; } }

@media screen and (max-width: 420px) {
  /* line 2016, workfiles/project/css/main.scss */
  .text-with-logo-right .logo-wrap img {
    float: none !important;
    display: block;
    margin: 0 auto; } }

/* line 2023, workfiles/project/css/main.scss */
.clients-word {
  padding: 30px 0; }

/* line 2027, workfiles/project/css/main.scss */
.text-with-icons-left {
  padding-left: 55px;
  min-height: 140px;
  margin-bottom: 30px; }

/* line 2033, workfiles/project/css/main.scss */
.text-with-icons-left.developmarket {
  background: url(../img/project/logo_example.png) no-repeat;
  background-size: 60px;
  background-position: top left; }

/* line 2039, workfiles/project/css/main.scss */
.text-with-icons-left h3 {
  color: #595959;
  margin: 0 0 15px; }

@media screen and (max-width: 992px) {
  /* line 2045, workfiles/project/css/main.scss */
  .text-with-icons-left {
    margin-bottom: 30px;
    min-height: initial; } }

/* line 2051, workfiles/project/css/main.scss */
.benefits-quote h3 {
  font-size: 30px;
  line-height: 1.4em;
  margin-bottom: 100px; }

@media screen and (max-width: 768px) {
  /* line 2058, workfiles/project/css/main.scss */
  .benefits-quote h3 {
    margin-bottom: 50px; } }

/* line 2063, workfiles/project/css/main.scss */
.benefits-quote.partners-quote h3 {
  margin-bottom: 0; }

/* line 2067, workfiles/project/css/main.scss */
.quote-with-image .quote-text {
  border-left: 1px solid #ccc;
  padding: 0 15px; }

@media screen and (max-width: 480px) {
  /* line 2073, workfiles/project/css/main.scss */
  .quote-with-image .quote-text {
    border: none; } }

/* line 2078, workfiles/project/css/main.scss */
.quote-with-image .quote-text a {
  color: #D60606; }

/* line 2082, workfiles/project/css/main.scss */
.quote-with-image .quote-text p, .quote-with-image .quote-text ul > li {
  font-size: 12px;
  line-height: 1.5; }

/* line 2087, workfiles/project/css/main.scss */
.quote-with-image h4 {
  color: #0b76ce; }

/* line 2091, workfiles/project/css/main.scss */
.quote-with-image .quote-text ul {
  margin-left: 38px; }

/* line 2095, workfiles/project/css/main.scss */
.quote-with-image .img-circle {
  margin: -34% auto 40px;
  border: 8px solid rgba(244, 244, 244, 0.8); }

@media screen and (max-width: 990px) {
  /* line 2101, workfiles/project/css/main.scss */
  .quote-with-image .img-circle {
    margin: 0 auto 20px; } }

/* line 2106, workfiles/project/css/main.scss */
.quote-with-image p, .quote-with-image h4 {
  padding-left: 40px; }

@media screen and (max-width: 420px) {
  /* line 2111, workfiles/project/css/main.scss */
  .quote-with-image {
    margin-bottom: 60px; } }

/* line 2116, workfiles/project/css/main.scss */
.page31 .quote-with-image {
  margin-top: 70px; }

/* line 2120, workfiles/project/css/main.scss */
.one-col-with-icon {
  padding: 30px 0;
  position: relative; }

/* line 2125, workfiles/project/css/main.scss */
.one-col-with-icon img {
  margin-top: 15px; }

/* line 2129, workfiles/project/css/main.scss */
.one-col-with-icon.lightgrey img {
  margin-top: -20px; }

/* line 2133, workfiles/project/css/main.scss */
.one-col-with-icon h2 {
  margin: 0 0 30px; }

@media screen and (max-width: 420px) {
  /* line 2138, workfiles/project/css/main.scss */
  .one-col-with-icon h3 {
    margin-top: 0; } }

/* line 2143, workfiles/project/css/main.scss */
.one-col-with-icon h4 {
  color: #0b76ce;
  font-size: 17px;
  margin: 10px 0 5px; }

/* line 2149, workfiles/project/css/main.scss */
.one-col-with-icon .unstyle-list ul > li:before {
  display: none; }

/* line 2153, workfiles/project/css/main.scss */
.full-width-background {
  height: 280px; }

/* line 2157, workfiles/project/css/main.scss */
.full-width-background h3, .full-width-background p, .full-width-background a {
  color: #fff; }

/* line 2161, workfiles/project/css/main.scss */
.full-width-background p, .full-width-background a {
  font-weight: 300; }

@media screen and (max-width: 420px) {
  /* line 2171, workfiles/project/css/main.scss */
  .full-width-background {
    height: auto; } }

@media screen and (max-width: 991px) {
  /* line 2182, workfiles/project/css/main.scss */
  .full-width-background {
    background-image: none !important; } }

/* CONTACT US FORM BEGIN */
/* line 2189, workfiles/project/css/main.scss */
input.powermail_field_error {
  border: 1px solid #c00 !important; }

/* line 2193, workfiles/project/css/main.scss */
.full-width-cta {
  height: 170px; }

/* line 2197, workfiles/project/css/main.scss */
.full-width-cta.lightblue {
  background-image: -webkit-linear-gradient(left, #1378d1 300px, #86bbe8 50%);
  background-image: linear-gradient(to right, #1378d1 300px, #86bbe8 50%); }

/* line 2202, workfiles/project/css/main.scss */
.full-width-cta h3 {
  color: #fff;
  margin: 0;
  padding: 70px 0; }

/* line 2208, workfiles/project/css/main.scss */
.news-extension {
  border-top: 1px solid #b6d6f1;
  border-bottom: 1px solid #b6d6f1; }

/* line 2213, workfiles/project/css/main.scss */
.news-menu .no-dots {
  margin: 0; }

/* line 2217, workfiles/project/css/main.scss */
.form-grey .btn-link {
  background-color: #cc0800 !important;
  margin-bottom: 20px; }

/* line 2222, workfiles/project/css/main.scss */
.form-grey .btn-link:hover, .form-grey .btn-link:active {
  background-color: #a30600 !important; }

/* line 2226, workfiles/project/css/main.scss */
.map-direction h4 {
  font-family: "Open Sans", sans-serif; }

/* -------------------*/
/* line 2232, workfiles/project/css/main.scss */
.news-menu .no-dots li {
  margin: 8px 0;
  padding: 0 10px;
  border-right: 1px solid #b6d6f1;
  font-family: "PT Serif", serif; }

/* line 2239, workfiles/project/css/main.scss */
.news-menu .no-dots li:last-of-type {
  border: none; }

/* line 2243, workfiles/project/css/main.scss */
.news-menu .no-dots li:before {
  display: none; }

/* line 2247, workfiles/project/css/main.scss */
.news-menu .no-dots li a {
  color: #999ba4;
  text-decoration: none; }

@media screen and (max-width: 1024px) {
  /* line 2253, workfiles/project/css/main.scss */
  .news-menu .no-dots li a {
    font-size: 12px; } }

/* line 2258, workfiles/project/css/main.scss */
.news-menu .no-dots li a:hover, .news-menu .no-dots li a:active, .news-menu .no-dots li a:focus {
  text-decoration: underline;
  color: #46484f; }

/* line 2263, workfiles/project/css/main.scss */
.login-section > p:first-of-type {
  font-weight: 600;
  font-size: 16px;
  margin-top: 50px; }

/* line 2269, workfiles/project/css/main.scss */
.login-section fieldset legend, .tx-felogin-pi1 legend {
  /*
      margin-top: 10px;
      display: inline-block;
  */
  display: none; }

/* line 2277, workfiles/project/css/main.scss */
.tx-felogin-pi1 h3 {
  /*
      margin-top:15px;
      margin-bottom:10px;
  */ }

/* line 2285, workfiles/project/css/main.scss */
.tx-felogin-pi1 form {
  margin-top: 10px; }

/* line 2289, workfiles/project/css/main.scss */
.form-contact h3 {
  margin-top: 20px; }

/* line 2293, workfiles/project/css/main.scss */
.form-contact .lead {
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px; }

/* line 2299, workfiles/project/css/main.scss */
.map-wrapper {
  margin-top: 10px; }

/* line 2303, workfiles/project/css/main.scss */
.potential-partners .left-column {
  margin-top: 40px; }

/* line 2307, workfiles/project/css/main.scss */
.potential-partners .left-column .btn-link {
  margin-top: 20px;
  display: inline-block; }

/* line 2312, workfiles/project/css/main.scss */
.potential-partners .left-column .btn-link:after {
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent; }

/* line 2317, workfiles/project/css/main.scss */
.get-involved .quote-with-image {
  margin-top: 30px; }

@media screen and (max-width: 430px) {
  /* line 2322, workfiles/project/css/main.scss */
  .get-involved .quote-with-image p {
    padding-left: 20px; } }

/* line 2327, workfiles/project/css/main.scss */
.how-it-works h3 {
  margin-top: 10px; }

@media screen and (width: 1024px) {
  /* line 2332, workfiles/project/css/main.scss */
  .info-blue-bckg {
    padding-left: 40px; } }

/* line 2337, workfiles/project/css/main.scss */
.partners-box-dropdown {
  border-bottom: 1px solid #ECECEC;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 2343, workfiles/project/css/main.scss */
.partners-box-dropdown:hover .resource-headline, .partners-box-dropdown:active .resource-headline, .partners-box-dropdown:focus .resource-headline {
  color: #0b76ce; }

/* line 2347, workfiles/project/css/main.scss */
.partners-box-dropdown:hover .resources-subhedline, .partners-box-dropdown:active .resources-subhedline, .partners-box-dropdown:focus .resources-subhedline {
  color: #46484f; }

/* line 2351, workfiles/project/css/main.scss */
.partners-box-dropdown .resources-subhedline {
  font-size: 18px;
  max-width: 82%;
  color: #838691;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 2359, workfiles/project/css/main.scss */
.partners-box-dropdown .resource-headline {
  display: table;
  cursor: pointer;
  margin: 0;
  padding: 20px 0 10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 2368, workfiles/project/css/main.scss */
.partners-box-dropdown .plus-icon, .login-dropdown .plus-icon, .plus-icon {
  position: absolute;
  width: 34px;
  height: 34px;
  background: url(/assets/img/project/icon/plus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  transition: all .4s ease; }

/* line 2382, workfiles/project/css/main.scss */
.minus-icon {
  width: 34px;
  height: 34px;
  background: url(/assets/img/project/icon/minus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  cursor: pointer;
  display: inline-block; }

@media only screen and (max-width: 480px) {
  /* line 2392, workfiles/project/css/main.scss */
  .partners-box-dropdown .plus-icon, .login-dropdown .plus-icon, .plus-icon {
    display: none; } }

/* line 2397, workfiles/project/css/main.scss */
.add-new-row {
  position: absolute; }

/* line 2401, workfiles/project/css/main.scss */
.add-new-row .plus-icon {
  left: 0px;
  top: 20px; }

/* line 2406, workfiles/project/css/main.scss */
#membershipTable tr:not(:first-child) .plus-icon {
  display: none; }

/* line 2410, workfiles/project/css/main.scss */
#membershipTable tr > td {
  border: none; }

/* line 2414, workfiles/project/css/main.scss */
.colored-bckg {
  background-color: #E9F1FA; }

/* line 2418, workfiles/project/css/main.scss */
.trigger-hover {
  position: relative;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

@media screen and (max-width: 768px) {
  /* line 2426, workfiles/project/css/main.scss */
  .trigger-hover {
    height: 100%; }
  /* line 2429, workfiles/project/css/main.scss */
  .trigger-hover h3.resource-headline {
    font-size: 20px; }
  /* line 2432, workfiles/project/css/main.scss */
  .trigger-hover p.resources-subhedline {
    font-size: 16px;
    line-height: 1.4; }
  /* line 2436, workfiles/project/css/main.scss */
  .partners-box-dropdown .plus-icon, .login-dropdown .plus-icon {
    right: 15px; } }

@media screen and (max-width: 480px) {
  /* line 2442, workfiles/project/css/main.scss */
  .trigger-hover p.resources-subhedline {
    line-height: 1.2; } }

/* line 2447, workfiles/project/css/main.scss */
.trigger-hover:hover, .trigger-hover:focus, .trigger-hover:active, .active-tab {
  background-color: #E9F1FA; }

/* line 2451, workfiles/project/css/main.scss */
.active-tab .resource-headline {
  color: #0b76ce; }

@media only screen and (max-width: 765px) {
  /* line 2456, workfiles/project/css/main.scss */
  .who-we-look-for-button {
    margin-bottom: 20px; } }

/* line 2461, workfiles/project/css/main.scss */
.partners-box-dropdown .active-tab span.plus-icon, .login-dropdown .active-tab span.plus-icon {
  background: url(/assets/img/project/icon/minus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  transition: all .4s ease; }

/* line 2467, workfiles/project/css/main.scss */
.trigger-in {
  display: block !important; }

/* line 2471, workfiles/project/css/main.scss */
.no-padding-left {
  padding-left: 0; }

/* line 2475, workfiles/project/css/main.scss */
.no-padding-right {
  padding-right: 0; }

/* line 2479, workfiles/project/css/main.scss */
.partners-inner {
  display: none; }

/* line 2483, workfiles/project/css/main.scss */
p.adress-icon {
  background: url(/assets/img/project/icon/home-icon@2x.png) no-repeat;
  background-position: left top; }

/* line 2488, workfiles/project/css/main.scss */
p.phone-icon {
  background: url(/assets/img/project/icon/phone-icon@2x.png) no-repeat;
  background-position: left; }

/* line 2493, workfiles/project/css/main.scss */
p.email-icon {
  background: url(/assets/img/project/icon/mail-icon@2x.png) no-repeat;
  background-position: left; }

/* line 2498, workfiles/project/css/main.scss */
p.adress-icon, p.phone-icon, p.email-icon {
  background-size: 20px;
  padding-left: 32px; }

/* line 2503, workfiles/project/css/main.scss */
.dark-bckg {
  background-image: -webkit-linear-gradient(top left, #5B5C5F, #27292e);
  background-image: linear-gradient(to bottom right, #5B5C5F, #27292e);
  padding: 10px 0 20px;
  /*position:absolute;*/
  width: 100%;
  left: 0;
  bottom: 0; }

@media screen and (max-width: 768px) {
  /* line 2514, workfiles/project/css/main.scss */
  .dark-bckg {
    position: static; } }

/* line 2519, workfiles/project/css/main.scss */
.dark-bckg p, .dark-bckg a, .dark-bckg h4, .dark-bckg span {
  color: #fff;
  font-weight: 300; }

/* line 2524, workfiles/project/css/main.scss */
.dark-bckg h4 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  display: inline-block;
  min-width: 200px; }

@media screen and (max-width: 480px) {
  /* line 2533, workfiles/project/css/main.scss */
  .dark-bckg h4 {
    font-size: 16px;
    min-width: 145px; } }

/* line 2539, workfiles/project/css/main.scss */
.dark-bckg p, .dark-bckg li > a {
  font-size: 12px; }

/* line 2543, workfiles/project/css/main.scss */
.dark-bckg p {
  text-transform: uppercase;
  line-height: 2; }

/* line 2548, workfiles/project/css/main.scss */
.dark-bckg p.lowercase {
  text-transform: none;
  line-height: 1.4; }

/* line 2553, workfiles/project/css/main.scss */
.vertical-nav {
  margin-top: -5px; }

/* line 2557, workfiles/project/css/main.scss */
.dark-bckg .vertical-nav li {
  margin: 5px 0; }

/* line 2561, workfiles/project/css/main.scss */
.dark-bckg .vertical-nav li a {
  text-transform: uppercase; }

/* line 2565, workfiles/project/css/main.scss */
.dark-bckg .vertical-nav li a:hover, .dark-bckg .vertical-nav li a:active, .dark-bckg .vertical-nav li a:focus {
  text-decoration: underline !important; }

/* line 2569, workfiles/project/css/main.scss */
.dark-bckg .testimonial {
  font-size: 12px;
  color: #828282; }

/* line 2574, workfiles/project/css/main.scss */
.dark-bckg .testimonial p {
  text-transform: none;
  color: #828282; }

/* line 2579, workfiles/project/css/main.scss */
.dark-bckg .testimonial .impressum-link, .dark-bckg .testimonial .toc-link {
  text-decoration: underline !important;
  margin-right: 10px;
  color: #828282; }

@media screen and (min-width: 440px) and (max-width: 769px) {
  /* line 2586, workfiles/project/css/main.scss */
  .dark-bckg .testimonial .impressum-link, .dark-bckg .testimonial .toc-link {
    margin-right: 0; } }

/* line 2591, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .form-control {
  padding: 4px 15px;
  border-radius: 0;
  font-size: 13px;
  background-color: #fff;
  border: none; }

/* line 2599, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .btn-link {
  background-color: #cc0800;
  padding: 4px 20px;
  font-size: 13px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 2607, workfiles/project/css/main.scss */
body.page17 .toc-link,
body.page29 .toc-link,
body.page31 .toc-link,
body.page29 .toc-link,
body.page33 .toc-link,
body.page17 .toc-link,
body.page35 .toc-link,
body.page50 .toc-link,
body.page51 .toc-link,
body.page41 .toc-link {
  display: none; }

/* line 2620, workfiles/project/css/main.scss */
body.page1 .impressum-link,
body.page3 .impressum-link,
body.page4 .impressum-link,
body.page20 .impressum-link,
body.page21 .impressum-link,
body.page22 .impressum-link,
body.page24 .impressum-link,
body.page25 .impressum-link,
body.page26 .impressum-link,
body.page28 .impressum-link,
body.page37 .impressum-link,
body.page38 .impressum-link,
body.page16 .impressum-link {
  display: none; }

/* line 2636, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .btn-link:hover {
  background-color: #a30600; }

/* line 2640, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .btn-link:after {
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 8px solid #cc0800;
  -webkit-transition: all .4s ease;
  transition: all .4s ease; }

/* line 2648, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .btn-link:hover:after {
  border-left: 9px solid #a30600; }

/* line 2652, workfiles/project/css/main.scss */
.dark-bckg .footer-newsletter .input-group {
  width: 90%; }

/* line 2656, workfiles/project/css/main.scss */
.dark-bckg .soc-icon {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: 20px 0;
  margin-right: 6px; }

/* line 2664, workfiles/project/css/main.scss */
.dark-bckg .soc-icon.facebook-icon {
  background: url(../img/project/icon/facebook_icon@2x.png) no-repeat;
  background-size: 34px; }

/* line 2669, workfiles/project/css/main.scss */
.dark-bckg .soc-icon.linkedin-icon {
  background: url(../img/project/icon/linkedin_icon@2x.png) no-repeat;
  background-size: 34px; }

/* line 2674, workfiles/project/css/main.scss */
ul.powermail_message.powermail_message_error {
  color: #d00; }

/* Become a partner link : BEGIN */
/* line 2680, workfiles/project/css/main.scss */
.main-navigation .linkitem-li-35,
.main-navigation .linkitem-li-17,
.main-navigation .linkitem-li-92,
.become-a-partner {
  position: relative;
  display: inline-block;
  font-weight: 400;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-family: "PT Serif", serif;
  box-shadow: 0 2px 3px -2px #404040;
  transition: all .4s ease;
  padding: 0;
  margin-top: 15px;
  margin-left: 20px !important;
  background: #cc0800;
  font-weight: 300; }

/* line 2700, workfiles/project/css/main.scss */
.main-navigation .linkitem-li-35 a,
.main-navigation .linkitem-li-92 a,
.main-navigation .linkitem-li-17 a {
  color: #fff !important;
  padding: 5px 15px;
  font-family: "Open Sans", sans-serif; }

/* line 2708, workfiles/project/css/main.scss */
.main-navigation .linkitem-li-35 a:hover,
ul.main-navigation li.linkitem-li-92 a:hover,
.main-navigation .linkitem-li-17 a:hover {
  text-decoration: none !important;
  background-color: #a30600 !important; }

@media screen and (max-width: 991px) {
  /* line 2716, workfiles/project/css/main.scss */
  .main-navigation .linkitem-li-35,
  .main-navigation .linkitem-li-92,
  .main-navigation .linkitem-li-17 {
    margin-left: 0;
    margin: 0 auto !important;
    max-width: 180px;
    display: block; } }

/* line 2726, workfiles/project/css/main.scss */
.become-a-partner {
  color: #fff;
  text-decoration: none !important;
  font-family: "Open Sans", sans-serif;
  padding: 5px 15px;
  margin: 0 !important; }

/* line 2734, workfiles/project/css/main.scss */
.become-a-partner:hover, .become-a-partner:focus, .become-a-partner:active {
  color: #fff;
  background-color: #a30600; }

/* line 2739, workfiles/project/css/main.scss */
.list-check, ul.powermail_message.powermail_message_error {
  padding-left: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc; }

/* line 2745, workfiles/project/css/main.scss */
.potential-partners .list-check {
  border: none; }

/* line 2749, workfiles/project/css/main.scss */
.list-highlight.list-check li {
  background: url("/assets/img/project/icon/check-red.png") no-repeat;
  background-size: 21px;
  background-position-x: 7px;
  padding-left: 45px;
  text-transform: uppercase;
  font-weight: 600; }

/* line 2758, workfiles/project/css/main.scss */
.list-check li:before, ul.powermail_message.powermail_message_error li:before {
  display: none; }

/* line 2762, workfiles/project/css/main.scss */
.list-check-grey li {
  background: url("/assets/img/project/icon/check-grey.png") no-repeat;
  background-size: 13px;
  background-position-x: 7px;
  background-position-y: 7px;
  padding-left: 45px; }

/* line 2771, workfiles/project/css/main.scss */
.testimonial {
  margin-top: 33px; }

/* Messages Center : BEGIN */
/* line 2777, workfiles/project/css/main.scss */
.message-listing .table {
  margin-bottom: 0px;
  margin-top: -5px; }

/* line 2782, workfiles/project/css/main.scss */
.create-new-message form .f3-form-error {
  width: 100%;
  resize: none !important;
  border: 1px solid #ccc; }

/* line 2789, workfiles/project/css/main.scss */
.message-list-single {
  padding: 20px 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 5px; }

/* line 2796, workfiles/project/css/main.scss */
.unread {
  background: #d6f1e5; }

/* line 2800, workfiles/project/css/main.scss */
.message-read-button {
  text-decoration: none !important;
  color: #999; }

/* line 2805, workfiles/project/css/main.scss */
.unread .message-read-button {
  text-decoration: none;
  color: #d00; }

/* line 2810, workfiles/project/css/main.scss */
.message-count {
  text-decoration: none !important; }

/* line 2814, workfiles/project/css/main.scss */
.message-count > span {
  margin-right: 6px; }

/* line 2818, workfiles/project/css/main.scss */
.message-attachment {
  /*
      border-bottom: 1px dotted $red-color;
      margin-bottom: 30px;
  */ }

/* line 2825, workfiles/project/css/main.scss */
.message-attachment thead > tr > th {
  visibility: hidden;
  border-bottom: none;
  position: absolute;
  top: -99999px;
  left: -99999px; }

/* line 2833, workfiles/project/css/main.scss */
.larger-col {
  width: 70%; }

/* line 2837, workfiles/project/css/main.scss */
.smaller-col {
  width: 15%; }

/* Cookies Policy Container : BEGIN */
/* line 2844, workfiles/project/css/main.scss */
.cc_container {
  font-size: 13px !important;
  padding: 10px 30px !important;
  font-family: 'Lato', sans-serif !important; }

/* line 2850, workfiles/project/css/main.scss */
.cc_container .cc_btn {
  background-color: #cc0800 !important;
  color: #fff !important; }

/* line 2855, workfiles/project/css/main.scss */
.cc_more_info {
  color: #2f8ad5 !important; }

/* line 2859, workfiles/project/css/main.scss */
.filter-link {
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  padding: 10px 0;
  display: block;
  border-top: 1px solid #ddd; }

/* line 2868, workfiles/project/css/main.scss */
.user-filter label {
  display: block; }

/* line 2872, workfiles/project/css/main.scss */
.login-nav ul {
  margin: 0 0 40px 0;
  padding: 0;
  border-top: 1px solid #ddd; }

/* line 2878, workfiles/project/css/main.scss */
.login-nav ul li {
  margin: 0;
  padding: 0; }

/* line 2883, workfiles/project/css/main.scss */
.login-nav ul > li:before {
  display: none; }

/* line 2887, workfiles/project/css/main.scss */
.login-nav ul li a {
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  display: block;
  box-sizing: border-box;
  background: #fbfbfb; }

/* line 2897, workfiles/project/css/main.scss */
.login-nav ul li.active a,
.login-nav ul li a:hover {
  background: #eee; }

/* line 2903, workfiles/project/css/main.scss */
.toc-container input {
  vertical-align: top; }

/* line 2907, workfiles/project/css/main.scss */
.toc-label {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: 300; }

/* line 2914, workfiles/project/css/main.scss */
.toc-label a {
  color: inherit; }

/* line 2918, workfiles/project/css/main.scss */
.social-link {
  text-decoration: none !important;
  font-size: 14px;
  /* 	text-transform: uppercase; */
  /* 	font-weight: 700; */
  display: inline-block; }

/* line 2926, workfiles/project/css/main.scss */
.social-link img {
  margin-right: 10px; }

/* line 2930, workfiles/project/css/main.scss */
.page58 p a {
  display: none; }

/* line 2934, workfiles/project/css/main.scss */
.bluish {
  color: #46484f; }

/* line 2938, workfiles/project/css/main.scss */
.ssbutton {
  background: #cc0800;
  border: 1px solid #cc0800;
  padding: 5px 10px; }

/* line 2944, workfiles/project/css/main.scss */
.unssbutton {
  background: #46484f;
  color: white;
  border: 1px solid #46484f;
  padding: 5px 10px; }

/* line 2951, workfiles/project/css/main.scss */
.ssbutton,
.unssbutton {
  color: white;
  text-decoration: none !important; }

/* line 2957, workfiles/project/css/main.scss */
a.ssbutton:hover,
a.unssbutton:hover {
  text-decoration: underline !important;
  color: white; }

/* line 2963, workfiles/project/css/main.scss */
footer {
  min-height: 340px; }

@media screen and (max-width: 992px) {
  /* line 2968, workfiles/project/css/main.scss */
  footer {
    min-height: 380px; } }

/* line 2973, workfiles/project/css/main.scss */
#map h3 {
  margin-top: 8px;
  margin-bottom: 5px; }

/* Added HTML */
/* line 2979, workfiles/project/css/main.scss */
.page48 .social-link {
  margin-bottom: 10px; }

@media screen and (min-width: 992px) {
  /* line 2985, workfiles/project/css/main.scss */
  .references h2 {
    margin-top: 0;
    margin-bottom: 80px; }
  /* line 2989, workfiles/project/css/main.scss */
  .references div[class*=col-] {
    border-right: 1px solid #dbdde0; }
  /* line 2993, workfiles/project/css/main.scss */
  .references div.col-md-3:hover > .references-item {
    position: relative;
    z-index: 999;
    min-height: 280px;
    box-shadow: 0 0 10px #dbdde0;
    margin: -20px; }
  /* line 3000, workfiles/project/css/main.scss */
  .references div.col-md-3:hover .references-item-description {
    /* 		margin: 0 20px; */ }
  /* line 3003, workfiles/project/css/main.scss */
  .references div.col-md-3 {
    border: 1px solid transparent;
    border-bottom: 1px solid #dbdde0;
    border-right: 1px solid #dbdde0; }
  /* line 3010, workfiles/project/css/main.scss */
  .references div.col-md-3:nth-of-type(4n + 4) {
    /* remove border-right to every fourth div */
    border-right-color: transparent; }
  /* line 3014, workfiles/project/css/main.scss */
  .references div.col-md-3:nth-child(n+6) {
    /* add border-top to all divs except first four*/ } }

/* END OF CE References */
/* CE:SiteSwitch */
/* container-fluid additional class CE: id=784 */
/* line 3023, workfiles/project/css/main.scss */
.site-switch-container {
  margin-top: 100px; }

/* line 3027, workfiles/project/css/main.scss */
.site-switch {
  cursor: pointer;
  border-right: 1px solid #ECECEC; }

/* line 3032, workfiles/project/css/main.scss */
.site-switch.hover {
  background-color: #0674D4; }

/* remove padding between cols */
/* line 3037, workfiles/project/css/main.scss */
.site-switch-container .hidden-xs .row > div,
.site-switch-container .hidden-md .row > div {
  padding-left: 0;
  padding-right: 0; }

/* line 3043, workfiles/project/css/main.scss */
.site-switch {
  border-bottom: 1px solid #ECECEC;
  background-color: #646464;
  /* 	background-color: #7da0bf; */ }

/* line 3049, workfiles/project/css/main.scss */
.site-switch-link {
  display: block; }

/* line 3053, workfiles/project/css/main.scss */
a.site-switch-link:hover {
  text-decoration: none !important; }

/* line 3057, workfiles/project/css/main.scss */
.site-switch-image {
  display: none;
  margin-bottom: 15px; }

/* line 3062, workfiles/project/css/main.scss */
h3.site-switch-headline {
  margin-top: 0;
  margin-bottom: 11px;
  color: #FFFFFF;
  font-family: "Open Sans", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 17px; }

/* line 3072, workfiles/project/css/main.scss */
.site-switch-inner {
  padding: 25px 15px;
  position: relative; }

/* line 3077, workfiles/project/css/main.scss */
.site-switch-inner p {
  margin: 0; }

/* line 3081, workfiles/project/css/main.scss */
.site-switch-content {
  color: #ECECEC;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 0; }

/* line 3089, workfiles/project/css/main.scss */
.site-switch-content span.text-left {
  font-style: normal;
  color: #fff;
  font-size: 12px;
  line-height: 30px;
  text-decoration: underline; }

/* line 3097, workfiles/project/css/main.scss */
.site-switch.active {
  position: relative;
  background-color: #0674D4; }

/* line 3103, workfiles/project/css/main.scss */
.site-switch.active:after, .site-switch.active:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

/* line 3114, workfiles/project/css/main.scss */
.site-switch.active:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #0674D4;
  border-width: 15px;
  margin-left: -15px; }

/* line 3121, workfiles/project/css/main.scss */
.site-switch.active:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 16px;
  margin-left: -16px; }

@media screen and (min-width: 768px) {
  /* line 3129, workfiles/project/css/main.scss */
  .site-switch-image {
    display: block; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  /* line 3135, workfiles/project/css/main.scss */
  .site-switch {
    padding: 25px 50px; }
  /* line 3138, workfiles/project/css/main.scss */
  .site-switch-inner {
    position: relative;
    padding-left: 130px; }
  /* line 3143, workfiles/project/css/main.scss */
  .site-switch-image {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 60px;
    height: auto;
    transform: translateY(-50%);
    width: 90px;
    height: auto; } }

@media screen and (min-width: 1200px) {
  /* line 3156, workfiles/project/css/main.scss */
  .site-switch {
    padding: 15px 50px; }
  /* line 3159, workfiles/project/css/main.scss */
  .site-switch-inner {
    position: relative;
    padding-left: 130px; }
  /* line 3164, workfiles/project/css/main.scss */
  .site-switch-image {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 60px;
    height: auto;
    transform: translateY(-50%);
    width: 90px;
    height: auto; } }

/* END OF CE:SiteSwitch */
/* line 3179, workfiles/project/css/main.scss */
.page92 ul.list.check.list-check-grey {
  padding-left: 0; }

/* line 3183, workfiles/project/css/main.scss */
.page85 .homepage-welcome-box .text-right em {
  font-size: 12px; }

/* line 3187, workfiles/project/css/main.scss */
span.admin-fielddescription.error {
  display: block;
  margin-top: 5px;
  line-height: 1.2;
  font-size: 12px;
  color: #c70c00;
  position: relative;
  top: -5px; }

/* line 3197, workfiles/project/css/main.scss */
.tooltip {
  width: 300px;
  max-width: 300px;
  opacity: 1 !important; }

/* line 3203, workfiles/project/css/main.scss */
.tooltip-inner {
  max-width: none;
  background: rgba(0, 0, 0, 0.6); }

/* line 3208, workfiles/project/css/main.scss */
.tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.6) !important; }

/* line 3212, workfiles/project/css/main.scss */
.small-darkicon {
  font-size: 16px; }

/* line 3216, workfiles/project/css/main.scss */
.form-group label, .form-group h4 {
  position: relative; }

/* 31.05.2017 */
/* CE References */
/* line 3223, workfiles/project/css/main.scss */
.reference_item--image {
  background-color: #dbdde0;
  width: 70%;
  max-width: 200px;
  height: auto;
  margin: 0 auto; }

/* line 3231, workfiles/project/css/main.scss */
.reference_item {
  position: relative;
  background-color: #fff;
  z-index: 1;
  transition: all 0.2s ease-in-out; }

/* line 3238, workfiles/project/css/main.scss */
.reference_item .reference_item--inner a,
.reference_item .reference_item--inner a:hover {
  text-decoration: none; }

/* Title */
/* line 3245, workfiles/project/css/main.scss */
.reference_item--title {
  margin-bottom: 15px;
  color: #1E1F22;
  transition: all .1s ease-in-out; }

/* Description  */
/* line 3252, workfiles/project/css/main.scss */
.reference_item--description {
  transition: all .1s ease-in-out;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #ccc;
  margin: 0; }

/* line 3261, workfiles/project/css/main.scss */
.reference_item.hover .reference_item--description {
  display: block;
  color: #000; }

/* Scale up the box */
@media only screen and (max-width: 991px) {
  /* line 3268, workfiles/project/css/main.scss */
  .reference_item {
    padding-bottom: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* line 3279, workfiles/project/css/main.scss */
  .reference_item {
    position: relative;
    width: 50%;
    margin: 25px auto; } }

@media only screen and (min-width: 992px) {
  /* line 3288, workfiles/project/css/main.scss */
  .reference_item.hover {
    height: 330px;
    margin-top: 0; }
  /* line 3292, workfiles/project/css/main.scss */
  .reference_item.hover {
    margin-top: -80px; }
  /* Create the hidden pseudo-element */
  /* include the shadow for the end state */
  /* line 3297, workfiles/project/css/main.scss */
  .reference_item:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease-in-out; }
  /* line 3307, workfiles/project/css/main.scss */
  .references:after {
    content: '';
    display: block;
    position: relative;
    bottom: 1px;
    z-index: 1;
    border-top: 1px solid #fff;
    background-color: #fff;
    min-height: 1px;
    width: 100%;
    clear: both; }
  /* line 3320, workfiles/project/css/main.scss */
  .reference_item--inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    -webkit-font-smoothing: antialiased;
    transition: all 0.2s ease-in-out; }
  /* line 3330, workfiles/project/css/main.scss */
  .reference_item:hover {
    bottom: -25px; }
  /* line 3335, workfiles/project/css/main.scss */
  .reference_item {
    position: relative;
    height: 250px; }
  /* line 3339, workfiles/project/css/main.scss */
  .reference_item:hover {
    transform-origin: center center;
    transform: scale(1.2, 1.2);
    z-index: 3; }
  /* line 3344, workfiles/project/css/main.scss */
  .reference_item:hover .reference_item--image,
  .reference_item:hover .reference_item--title,
  .reference_item:hover .reference_item--description {
    transform: scale(0.9, 0.9); }
  /* Create the hidden pseudo-element */
  /* include the shadow for the end state */
  /* line 3351, workfiles/project/css/main.scss */
  .reference_item:after {
    opacity: 0; }
  /* line 3355, workfiles/project/css/main.scss */
  .reference_item--description {
    display: none; }
  /* Fade in the pseudo-element with the bigger shadow */
  /* line 3359, workfiles/project/css/main.scss */
  .reference_item.hover:after {
    opacity: 1; } }

/* 19.06. 2017 */
/* footer - adds crafted-by section */
/* line 3366, workfiles/project/css/main.scss */
.footer-crafted-by-container {
  border-top: 1px solid #585858;
  font-size: 11px; }

/* line 3371, workfiles/project/css/main.scss */
.dark-bckg .footer-crafted-by .footer-crafted-by-link {
  color: #828282; }

/* line 3375, workfiles/project/css/main.scss */
.dark-bckg .footer-crafted-by .footer-crafted-by-link:hover {
  color: #fff; }

/* line 3379, workfiles/project/css/main.scss */
.footer-crafted-by-link:hover {
  text-decoration: underline !important; }

/* line 3383, workfiles/project/css/main.scss */
.dark-bckg .footer-crafted-by p {
  margin-bottom: 0; }

/* line 3387, workfiles/project/css/main.scss */
.footer-crafted-by-link {
  display: inline-block;
  padding: 10px 0;
  font-size: 11px;
  line-height: 15px;
  text-transform: none; }

/* line 3395, workfiles/project/css/main.scss */
.dark-bckg .footer-crafted-by {
  font-size: 11px;
  line-height: 15px; }

/* 2017-30-06 */
/* Cookies container */
/* line 3402, workfiles/project/css/main.scss */
div.cc_container {
  background: rgba(34, 34, 34, 0.9); }

/* line 3406, workfiles/project/css/main.scss */
.dark-bckg {
  padding-bottom: 50px;
  -webkit-transition: padding-bottom .4s ease;
  transition: padding-bottom .4s ease; }
