@import "./constants/constants";
@import "./base/mixins";

@charset "UTF-8";

@font-face {
  font-family: "project";
  src: url(/assets/fonts/project/project.eot);
  src: url(/assets/fonts/project/project.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/project/project.woff) format("woff"), url(/assets/fonts/project/project.ttf) format("truetype"), url(/assets/fonts/project/project.svg#project) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-icon]:before {
  font-family: "project" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="project-"]:before, [class*=" project-"]:before {
  font-family: "project" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.project-cancel:before {
  content: "a";
}

.project-check:before {
  content: "b";
}

.project-facebook:before {
  content: "c";
}

.project-google:before {
  content: "d";
}

.project-minus:before {
  content: "e";
}

.project-plus:before {
  content: "f";
}

.project-twiter:before {
  content: "g";
}

.project-uncheck:before {
  content: "h";
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: $color-white url(./../img/slick/ajax-loader.gif) center center no-repeat;
}

@font-face {
  font-family: "slick";
  src: url(/assets/fonts/slick/slick.eot);
  src: url(/assets/fonts/slick/slick.eot?#iefix) format("embedded-opentype"), url(/assets/fonts/slick/slick.woff) format("woff"), url(/assets/fonts/slick/slick.ttf) format("truetype"), url(/assets/fonts/slick/slick.svg#slick) format("svg");
  font-weight: 400;
  font-style: normal;
}

.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: $color-white;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 30px;
  z-index: 1000;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 30px;
  z-index: 1000;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: $color-black;
  opacity: .25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: $color-black;
  opacity: .75;
}

.margin-none-top {
  margin-top: 0;
}

.margin-none-top h2 {
  margin-top: 0;
}

.margin-small-top {
  margin-top: 15px;
}

.margin-middle-top {
  margin-top: 40px;
}

.margin-large-top {
  margin-top: 60px;
}

.margin-none-right {
  margin-right: 0;
}

.margin-small-right {
  margin-right: 15px;
}

.margin-middle-right {
  margin-right: 40px;
}

.margin-large-right {
  margin-right: 60px;
}

.margin-none-bottom {
  margin-bottom: 0;
}

.margin-small-bottom {
  margin-bottom: 15px;
}

.margin-middle-bottom {
  margin-bottom: 40px;
}

.margin-large-bottom {
  margin-bottom: 60px;
}

.margin-none-left {
  margin-left: 0;
}

.margin-small-left {
  margin-left: 15px;
}

.margin-middle-left {
  margin-left: 40px;
}

.margin-large-left {
  margin-left: 60px;
}

.padding-none-top {
  padding-top: 0;
}

.padding-small-top {
  padding-top: 15px;
}

.padding-middle-top {
  padding-top: 40px;
}

.padding-large-top {
  padding-top: 60px;
}

.padding-none-right {
  padding-right: 0;
}

.padding-small-right {
  padding-right: 15px;
}

.padding-middle-right {
  padding-right: 40px;
}

.padding-large-right {
  padding-right: 60px;
}

.padding-none-bottom {
  padding-bottom: 0;
}

.padding-small-bottom {
  padding-bottom: 15px;
}

.padding-middle-bottom {
  padding-bottom: 40px;
}

.padding-large-bottom {
  padding-bottom: 60px;
}

.padding-none-left {
  padding-left: 0;
}

.padding-small-left {
  padding-left: 15px;
}

.padding-middle-left {
  padding-left: 40px;
}

.padding-large-left {
  padding-left: 60px;
}

.darkgrey {
  background-color: #38393D;
}

.lightgrey {
  background-color: #F1F4FF;
}

.light-bulb {
  background: url(../img/project/icon/light_bulb.png) no-repeat $color-white;
  background-size: 40px;
  background-position: top 20px center;
}

.blue-key {
  background: url(../img/project/icon/blue_key.png) no-repeat $color-white;
  background-size: 40px;
  background-position: center top 20px;
}

.download-icon {
  background: url(../img/project/icon/download_icon.png) no-repeat $color-white;
  background-size: 40px;
  background-position: center top 20px;
}

.experts-icon {
  background: url(../img/project/icon/experts_icon@2x.png) no-repeat $color-white;
  background-size: 40px;
  background-position: left top;
}

.globe-icon {
  background: url(../img/project/icon/globe_icon@2x.png) no-repeat $color-white;
  background-size: 40px;
  background-position: left top;
}

.rocket-icon {
  background: url(../img/project/icon/rocket_icon@2x.png) no-repeat $color-white;
  background-size: 40px;
  background-position: left top;
}

.reduce-costs-icon {
  background: url(../img/project/icon/reduce_costs_icon@2x.png) no-repeat $color-white;
  background-size: 40px;
  background-position: left top;
}

.arrows-left-right {
  background: url(../img/project/icon/arrows_left_right.png) no-repeat $color-white;
  background-size: 40px;
  background-position: left top;
}

.triumph-gate {
  background: url(../img/project/icon/german_menagement_icon@2x.png) no-repeat $color-white;
  background-size: 40px;
  background-position: center top 20px;
}

.int-platfotm {
  background: url(../img/project/icon/int_platform_icon@2x.png) no-repeat $color-white;
  background-size: 68px;
  background-position: center top 20px;
}

.suit-case {
  background: url(../img/project/icon/suit_case_icon.png) no-repeat $color-white;
  background-size: 40px;
  background-position: center top 20px;
}

.invest-icon {
  background: url(../img/project/icon/invest-icon@2x.png) no-repeat $color-white;
  background-size: 35px;
  background-position: center top 25px;
}

.export-icon {
  background: url(../img/project/icon/export-icon@2x.png) no-repeat $color-white;
  background-size: 30px 40px;
  background-position: center top 20px;
}

.supply-icon {
  background: url(../img/project/icon/supply-icon@2x.png) no-repeat $color-white;
  background-size: 35px 25px;
  background-position: center top 35px;
}

.navbar-slim {
  padding: 0 0 7px !important;
  box-shadow: 0 1px 15px -6px #686868;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-top: 96px;
  margin: 0;
  font-weight: 300;
}

@media screen and (max-width: 768px) {
  body {
    margin: 0;
  }


}

@media screen and (max-width: 480px) {
  body {
    padding-top: 85px;
  }

  body h1, body h2 {
    font-size: 38px;
    margin-bottom: 10px;
  }

  body h3 {
    font-size: 28px !important;
    line-height: 1.3 !important;
  }
}

body p {
  margin: 0 0 20px;
}

body .container {
  max-width: 1100px;
}

body .container a {
  text-decoration: underline;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

body .container a.btn-link {
  text-decoration: none;
}

body.page2, body.page1, body.page29 {
  min-height: 665px;
  width: 100%;
}

body header a, body footer a {
  text-decoration: none !important;
}

@media screen and (max-width: 769px) {
  body.page35 {
    overflow-x: hidden;
  }
  body.page17 {
    overflow-x: hidden;
  }
}

body.page35 .main-navigation .linkitem-li-35,
body.page17 .main-navigation .linkitem-li-17 {
  background: #DBDDE0;
}

body.page35 .main-navigation .linkitem-li-35 a:hover,
body.page35 .main-navigation .linkitem-li-35 a:active,
body.page17 .main-navigation .linkitem-li-17 a:hover,
body.page17 .main-navigation .linkitem-li-17 a:active {
  background: #c3c3c3 !important;
}

body.page35 .main-navigation .linkitem-li-35 a,
body.page17 .main-navigation .linkitem-li-17 a {
  color: $text-gray !important;
}

.navbar-default {
  padding: 20px 0;
  box-shadow: 0 1px 15px -6px #686868;
  margin-bottom: 0;
  -webkit-transition: all .6s ease;
  transition: all .6s ease;
}

@media screen and (max-width: 992px) {
  .navbar-default {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .navbar-default {
    padding: 10px 0;
  }
}

body.page2 .navbar-default,
body.page1 .navbar-default,
body.page29 .navbar-default,
body.page85 .navbar-default {
  background: rgba(255, 255, 255, 0);
  box-shadow: none;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
}

.navbar-default .main-navigation {
  display: table;
  margin-left: 40px !important;
  float: left;
  transition: all .4s ease;
}

@media screen and (max-width: 991px) {
  .navbar-default .main-navigation {
    width: 100%;
    float: none;
    margin: 0 auto !important;
  }
}

.navbar-header .navbar-toggle, .navbar-toggle.collapsed {
  border: none;
  margin-top: 12px;
}

.navbar-toggle.collapsed:hover, .navbar-toggle.collapsed:active, .navbar-toggle.collapsed:focus {
  background: none;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #ed1c24;
}

/* BECOME A PARTNER BUTTON -- SHOW ON HIGHER RESOLUTIONS */

.navbar-default .navbar-partner {
  float: right;
  margin: 2px 10px -5px 0;
  transition: all .4s ease;
}

.navbar-partner .show-desktop {
  margin-right: 30px;
}

.navbar-partner li > a {
  padding: 10px 0 0;
}

/* BECOME A PARTNER BUTTON -- SHOW ON LOWER RESOLUTIONS */

.navbar-partner .show-mobile {
  position: relative;
  display: none;
  border: none;
  padding: 0;
  background: $red-color;
  margin-left: -15px;
  margin-top: 12px;
  transition: all .4s ease;
  border-radius: 2px !important;
  box-shadow: none !important;
}

@media screen and (max-width: 1220px) {
  .navbar-partner .show-mobile {
    display: inline-block;
  }
  .navbar-partner .show-desktop {
    display: none;
  }
}

@media screen and (max-width: 1190px) {
  .navbar-nav.main-navigation li:not(.linkitem-li-92) > a,
  .navbar-nav.main-navigation li:not(.linkitem-li-35) > a,
  .navbar-nav.main-navigation li:not(.linkitem-li-17) > a {
    padding: 10px;
  }
  .main-navigation .linkitem-li-35,
  .main-navigation .linkitem-li-92,
  .main-navigation .linkitem-li-17 {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .show-mobile {
    position: absolute !important;
    width: 80%;
    text-align: center;
    left: 15%;
    margin: 0 20px;
    top: 7px;
  }
}

.navbar-partner .show-mobile:hover, .navbar-partner .show-mobile:focus, .navbar-partner .show-mobile:active {
  background-color: $background-red;
}

.navbar-partner .show-mobile:hover a:after,
.navbar-partner .show-mobile:focus a:after,
.navbar-partner .show-mobile:active a:after {
  border-left: 10px solid $background-red;
}

.navbar-partner .show-mobile a {
  color: $color-white !important;
  font-weight: 300;
  padding: 2px 7px;
  font-family: $font-head;
  font-size: 13px;
  text-transform: none !important;
}

.navbar-partner .show-mobile a:after {
  display: none;
}

.red-link {
  color: #CC0000 !important;
}

.navbar-default .navbar-partner span.become-partner {
  padding: 10px 20px;
  color: $text-gray;
  margin: -20px 0 0;
  display: inline-block;
  font-size: 11px;
  line-height: 1.4;
  text-transform: none;
  transition: all .6s ease;
}

body.page1 .navbar-default .navbar-partner span.become-partner,
body.page85 .navbar-default .navbar-partner span.become-partner,
body.page29 .navbar-default .navbar-partner span.become-partner {
  color: #f4f4f4;
}

span.become-partner:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: url('/assets/img/project/icon/banner-arrow-left@2x.png') no-repeat;
  background-size: 20px;
  left: -8px;
  top: 6px;
}

span.become-partner:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: url('/assets/img/project/icon/banner-arrow-right@2x.png') no-repeat;
  background-size: 20px;
  right: -25px;
  top: 6px;
}

.banner-light:before {
  background: url('/assets/img/project/icon/banner-arrow-left-w@2x.png') no-repeat !important;
  background-size: 20px !important;
  width: 40px;
  height: 40px;
  transition: all .4s ease;
}

.banner-light:after {
  background: url('/assets/img/project/icon/banner-arrow-right-w@2x.png') no-repeat !important;
  background-size: 20px !important;
  width: 40px;
  height: 40px;
  transition: all .4s ease;
}

.navbar-default .navbar-collapse.collapse {
  border: none;
  position: relative;
  box-shadow: none;
  margin-left: 180px;
}

@media screen and (max-width: 991px) {
  .navbar-default .navbar-collapse.collapse,
  .navbar-default .navbar-collapse.collapse.in {
    padding-bottom: 20px;
    margin: 0 -15px !important;
  }
}

.navbar-default ul li {
  margin: 6px 0 0;
}

/* LANGUAGES AND LOGIN BEGIN */


.languages-and-login {
  margin-top: 15px;
}

/*
.languages-and-login {
	position: relative;
	margin-top: -2px;
	transition: all .6s ease;
	width:100px;
}

.languages-and-login span {
	position: absolute;
    top: 0;
    right: 8px;
    font-size: 12px;
    color: #999;
	transition: all .6s ease;
}
*/

body.page1 .languages-and-login a,
body.page29 .languages-and-login a,
body.page85 .languages-and-login a,
body.page1 .languages-and-login span,
body.page85 .languages-and-login span,
body.page29 .languages-and-login span {
  color: $color-white;
}

@media screen and (min-width: 992px) {
  .languages-and-login {
    font-size: $font-size-items;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 991px) {
  .languages-and-login {
    margin-top: 70px;
    text-align: center;
  }
  .languages-and-login span {
    top: 12px;
    color: #d00 !important;
    margin-top: 50px;
    display: block;
    color: #d00 !important;
  }
  .languages-and-login span a {
    color: #d00 !important;
  }

  body.page85 .languages-and-login,
  body.parent85 .languages-and-login,
  body.page85 .languages-and-login,
  body.parent85 .languages-and-login {
    margin-top: 0 !important;
  }
}

.languages-and-login span > a {
  color: #999;
}

.navbar-default .navbar-languages {
  margin-top: 12px;
}

.navbar-default .navbar-languages li a {
  padding: 4px !important;
  margin: 0 4px;
}

body.page1 .navbar-default .navbar-languages li a,
body.page85 .navbar-default .navbar-languages li a,
body.page29 .navbar-default .navbar-languages li a {
  color: $color-white;
}

.navbar-default .navbar-languages li.active a {
  color: #0b76ce;
}

.bluetext {
  color: #0b76ce;
}

span.underlined {
  text-decoration: underline;
}

/* LOG IN - LOG OUT */

.login-section .lead {
  color: $red-color !important;
}

.form-horizontal .control-label {
  text-align: left !important;
}

.click-container, .click-container2, .click-container3 {
  display: none;
}

.click-container.show-input,
.click-container2.show-input,
.click-container3.show-input {
  display: block;
  margin-top: 10px;
}

.indented-right {
  margin-left: 40px;
  margin-bottom: 10px;
}

.checkboxes-wrap {
  padding-left: 20px;
}

.checkboxes-wrap strong {
  font-size: $font-size-items;
  font-weight: normal;
}

.col-xs-12.uploads {
  padding-left: 0;
}

.account-information .form-control {
  margin-bottom: 20px;
}

.small-description {
  font-family: $font-head;
  line-height: 1.4;
  font-size: $font-size;
  clear: both;
  display: block;
  line-height: 1.4;
}

.login-dropdown hr {
  margin-top: 0;
}

.login-dropdown .control-label {
  padding-left: 0;
  line-height: 1.3;
  padding-top: 10px;
}

.login-dropdown .trigger-hover:hover,
.login-dropdown .trigger-hover:active,
.login-dropdown .trigger-hover:focus {
  background-color: transparent;
}

.login-dropdown .active-tab,
.login-dropdown .trigger-hover:hover,
.login-dropdown .trigger-hover:focus,
.login-dropdown .trigger-hover:active {
  background-color: transparent;
}

.default-label-small {
  font-family: $font-head !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  cursor: pointer !important;
}

/* .checkbox label:last-of-type { margin: 0px !important; } */

.error-border {
  border: 1px solid $red-color;
}

.error-message {
  background: $red-color;
  padding: 10px 25px;
  border-radius: 3px;
  color: $color-white;
  margin: 10px 0;
  display: inline-block;
}

.subheadline {
  font-size: $font-size-items;
}

i.checkbox-label {
  display: inline-block;
  margin-top: 10px;
}


/* SELECT2 PLUGIN */

.select2.select2-container {
  width: 340px !important;
}

.select2-results__option {
  margin: 0 !important;
}

.select2-search__field {
  display: none;
}

.select2-container--default .select2-selection--single {
  background-color: #e2e3e7;
  border: 1px solid $gray-light;
  border-radius: 3px;
  height: 40px;
}

.select2-selection__rendered {
  padding-top: 5px;
  padding-left: 20px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 8px;
  right: 8px;
}


/* TOOLTIPSTER PLUGIN */

/*
.tooltip {
	width: 100%;
	height: 100%;
    top: 0;
}
*/

.default-label-small {
  position: relative;
}

/* _________________________*/

/*
.flag-bckg {
	background: url('/assets/img/project/flag-germany.png') no-repeat;
	background-size: 50%;
	background-position: center center;
	text-indent: -99999px;
	width: 35px;
	height: 30px;
}

.flag-bckg.last-li {
	background: url('/assets/img/project/flag-united-kingdom.png') no-repeat;
	background-size: 50%;
	background-position: center center;
}
*/

/*
.navbar-default .navbar-languages li.active a:after {
	content:"";
	position:absolute;
	top:100%;
	left:50%;
	-webkit-transform:translateX(-50%);
	transform:translateX(-50%);
	width:0;
	height:0;
	border-left:7px solid transparent;
	border-right:7px solid transparent;
	border-top:7px solid #0b76ce;
}
*/
/*

.navbar-default .navbar-languages li.active a:hover {
	color:$color-white;
}
*/

@media screen and (max-width: 991px) {
  .navbar-default .navbar-languages li {
    display: inline-block;
  }
}

.navbar > .container .navbar-brand {
  margin-left: 0;
}

.logo {
  display: block;
  padding: 0;
  margin-top: 5px;
  max-width: 180px;
}

@media screen and (max-width: 768px) {
  .logo {
    margin-left: 15px !important;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    max-width: 140px;
  }
}

.logo img {
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
}

.header-image-wrapper {
  height: 320px;
  overflow: hidden;
  margin-top: -5px;
}

body.page1 .header-image-wrapper,
body.page2 .header-image-wrapper,
body.page85 .header-image-wrapper,
body.page29 .header-image-wrapper {
  height: auto;
  margin-top: -100px;
}

@media only screen and (max-width: 1366px) {
  .header-image-wrapper {
    height: 200px;
  }
}

@media only screen and (max-width: 800px) {
  .header-image-wrapper {
    height: 160px;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 480px) {
  .header-image-wrapper {
    height: 80px;
  }
}

.header-image-wrapper .headline {
  position: absolute;
}

.header-image-wrapper .headline h2 {
  color: $color-white;
  min-width: 450px;
  margin: 0;
  font-size: 64px;
  padding: 120px 30px 20px 30px;
  text-shadow: 0 0 100px $color-black;

}

@media screen and (max-width: 1366px) {
  .header-image-wrapper .headline h2 {
    padding: 70px 0 20px 30px;
    min-width: 380px;
  }
}

@media screen and (max-width: 1024px) {
  .header-image-wrapper .headline h2 {
    padding: 55px 0 20px 30px;
    min-width: 360px;
  }
}

@media screen and (max-width: 768px) {
  .header-image-wrapper .headline h2 {
    padding: 40px 30px 20px 30px;
    min-width: 320px;
  }
}

@media screen and (max-width: 420px) {
  .header-image-wrapper .headline h2 {
    padding: 20px 0 20px 15px;
    min-width: 250px;
    font-size: 27px;
  }
}

body.page2 .main-navigation,
body.page1 .main-navigation,
body.page29 .main-navigation,
body.page85 .main-navigation {
  position: relative;
}

body.page2 .main-navigation li.active a,
body.page1 .main-navigation li.active a,
body.page29 .main-navigation li.active a,
body.page85 .main-navigation li.active a {
  color: $color-white;
  text-decoration: underline !important;
}

body.page2 .main-navigation li.active a:hover,
body.page2 .main-navigation li.active a:active,
body.page2 .main-navigation li.active a:focus,
body.page1 .main-navigation li.active a:hover,
body.page1 .main-navigation li.active a:active,
body.page1 .main-navigation li.active a:focus,
body.page85 .main-navigation li.active a:hover,
body.page85 .main-navigation li.active a:active,
body.page85 .main-navigation li.active a:focus,
body.page29 .main-navigation li.active a:hover,
body.page29 .main-navigation li.active a:active,
body.page29 .main-navigation li.active a:focus {
  color: $color-white;
}

body.page2 .main-navigation li > a,
body.page1 .main-navigation li > a,
body.page29 .main-navigation li > a,
body.page85 .main-navigation li > a {
  color: $color-white;
  font-weight: 100;
  -webkit-transition: all .6s ease-in-out;
  transition: all .6s ease-in-out;
}

body.page1 .main-navigation li > a:hover,
body.page2 .main-navigation li > a:hover,
body.page29 .main-navigation li:not(.linkitem-li-35) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-92) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-35) > a:hover,
body.page85 .main-navigation li:not(.linkitem-li-17) > a:hover {
  text-decoration: underline !important;
  color: $color-white;
}

@media screen and (max-width: 992px) {
  body.page2 .navbar-collapse,
  body.page1 .navbar-collapse,
  body.page29 .navbar-collapse,
  body.page85 .navbar-collapse {
    background-color: $color-white;
  }

  body.page2 .navbar-collapse .navbar-nav li > a,
  body.page1 .navbar-collapse .navbar-nav li > a,
  body.page29 .navbar-collapse .navbar-nav li > a,
  body.page85 .navbar-collapse .navbar-nav li > a {
    color: $text-gray;
  }

  body.page1 .navbar-collapse .navbar-nav li > a:hover,
  body.page1 .navbar-collapse .navbar-nav li > a:focus,
  body.page29 .navbar-collapse .navbar-nav li > a:hover,
  body.page29 .navbar-collapse .navbar-nav li > a:focus,
  body.page85 .navbar-collapse .navbar-nav li > a:hover,
  body.page85 .navbar-collapse .navbar-nav li > a:focus {
    color: #0b76ce;
  }

}

.homepage-welcome-box {
  margin-top: 140px;
}

.homepage-welcome-box h1, .homepage-welcome-box h2, .homepage-welcome-box h3, .homepage-welcome-box p {
  color: $color-white;
}

.homepage-welcome-box h3 {
  margin-top: 0;
}

.pointer-down {
  position: relative;
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .homeboxes {
    margin-top: 190px;
  }
}

@media screen and (max-width: 480px) {
  .homeboxes {
    margin-top: 80px;
  }
}

.partners-boxes .homebox {
  margin-top: 10%;
  min-height: 290px;
}

@media screen and (max-width: 768px) {
  .partners-boxes .homebox {
    min-height: 210px;
    margin-top: 6%;
  }
}

.partners-boxes .homebox h3 {
  margin-top: 0;
}

.partners-word {
  overflow: hidden;
  max-height: 340px;
  text-align: center;
}

.homebox {
  border: 1px solid #4678CD;
  border-radius: 0;
  padding: 55px 30px 20px;
  margin-top: 40%;
  min-height: 350px;
}

@media screen and (max-width: 1024px) {
  .homebox {
    margin-top: 50px;
    min-height: 388px;
  }
}

@media screen and (max-width: 992px) {
  .homebox {
    min-height: 240px;
  }
}

.homebox h3 {
  margin-top: 20px;
}

.homebox h3 > a {
  color: $text-gray;
  text-decoration: none;
}

.homebox h3 > a:hover, .homebox h3 > a:active, .homebox h3 > a:focus {
  text-decoration: underline;
}

body.page29 .homebox {
  min-height: 330px;
  margin-top: 40%;
}

@media screen and (max-width: 1024px) {
  body.page29 .homebox {
    min-height: 395px;
  }
}

@media screen and (max-width: 769px) {
  body.page29 .homebox {
    min-height: initial;
    margin: 0 auto 20px;
  }
  .partners-word {
    max-height: none;
  }
}

.home-section .btn-link {
  margin-top: 15px;
}

.about-us-section h1 {
  margin-top: 95px;
}

.about-us-section .btn-link {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .about-us-section h1 {
    margin-top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .about-us-section h1 {
    margin-top: 57px;
  }
}

.blue-link {
  position: relative;
  display: inline-block;
  font-size: 26px;
  text-decoration: none !important;
  color: $text-gray;
  font-family: $font-pt-serif;
  height: 82px;
  padding-left: 155px;
  background: url(/assets/img/project/icon/pointer-big@2x.png) no-repeat;
  background-size: 137px;
  background-position: left 6px;
}

@media screen and (max-width: 1024px) {
  .blue-link {
    font-size: 24px;
  }
}

.blue-link > span {
  font-size: $font-size;
  color: gray;
  font-family: $font-head;
  margin-top: -5px;
  display: block;
}

.blue-link .red-link {
  display: block;
  margin-top: -30px;
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .blue-link {
    background: none;
    padding-left: 0;
    font-size: 22px;
  }

  .blue-link:after, .blue-link:before {
    width: 100% !important;
    left: 0;
  }
}

.blue-link:hover, .blue-link:focus, .blue-link:active {
  color: #242427;
}

.form-bckg {
  background-color: $background-gray;
}

.form-group, .checkbox {
  margin-bottom: 20px;
}

.form-group label, .checkbox label {
  font-family: $font-pt-serif;
  font-size: $font-size-items;
}

.form-group label.powermail_label, .checkbox label.powermail_label {
  padding-left: 0;
}

.form-grey .checkbox label:last-of-type {
  font-family: $font-head;
  font-size: 13px;
  font-weight: 300;
  margin: 10px 0;
}

.radio-small label {
  font-family: $font-head;
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
}

.form-control {
  background-color: #e2e3e7;
  height: auto;
  padding: 8px 20px;
  box-shadow: none;
  font-weight: 300;
  border-radius: 3px;
  -webkit-appearance: none;
}

@media screen and (max-width: 1024px) {
  ul.main-navigation > li {
    display: block;
    text-align: center;
  }
}

.lead-box p {
  font-size: 18px;
}

@media screen and (max-width: 500px) {
  .lead-box p {
    font-size: $font-size-items;
  }
}

.sublead-box h2 {
  font-size: 36px;
}

/* IMAGE TEXT SLIDER : BEGINS */

.image-text-slider {
  margin-top: 70px;
}

.image-text-slider .slider-item {
  padding: 0 60px;
}

.image-text-slider .slider-item img {
  display: block;
  border-radius: 100%;
  margin: 0 auto 10px;
}

@media screen and (max-width: 480px) {
  .image-text-slider .slider-item img {
    width: 100%;
    height: 100%;
  }
  .image-text-slider .slick-prev {
    margin-left: -20px !important;
  }
  .image-text-slider .slick-next {
    margin-right: -20px !important;
  }
  .image-text-slider .slick-prev, .image-text-slider .slick-next {
    margin-top: -120px !important;
  }
}

.image-text-slider h4 {
  font-family: $font-head;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 8px;
}

.image-text-slider p {
  font-size: $font-size;
}

.image-text-slider .slick-prev, .image-text-slider .slick-next {
  width: 40px;
  height: 52px;
  margin-top: -80px;
  transition: all .3s;
}

.image-text-slider .slick-prev {
  margin-left: 20px;
}

.image-text-slider .slick-next {
  margin-right: 20px;
}

@media screen and (max-width: 1024px) {
  .image-text-slider .slick-prev {
    margin-left: 10px;
  }
  .image-text-slider .slick-next {
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  .image-text-slider .slick-prev {
    margin-left: 0px;
  }
  .image-text-slider .slick-next {
    margin-right: 0px;
  }
}

.image-text-slider .slick-prev:before, .image-text-slider .slick-next:before {
  visibility: hidden;
}

.image-text-slider .slick-prev {
  background: url('/assets/img/project/icon/arrow_left_grey@2x.png') no-repeat;
  background-size: 26px;
  background-position: center;
}

.image-text-slider .slick-prev:hover, .image-text-slider .slick-prev:focus, .image-text-slider .slick-prev:active {
  background: url('/assets/img/project/icon/arrow_left_blue@2x.png') no-repeat;
  background-size: 26px;
  background-position: center;
}


.image-text-slider .slick-next {
  background: url('/assets/img/project/icon/arrow_right_grey@2x.png') no-repeat;
  background-size: 26px;
  background-position: center;
}

.image-text-slider .slick-next:hover, .image-text-slider .slick-next:focus, .image-text-slider .slick-next:active {
  background: url('/assets/img/project/icon/arrow_right_blue@2x.png') no-repeat;
  background-size: 26px;
  background-position: center;
}

.image-text-slider .slick-dots {
  display: none !important;
}

/* IMAGE TEXT SLIDER : ENDS */

.partners-map, .partners-map-mobile {
  background: url('/assets/img/project/partners_map.jpg') no-repeat;
  background-size: cover;
  height: 440px;
}

@media screen and (max-width: 768px) {
  .partners-map {
    background: none;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .partners-map-mobile {
    margin-top: 20px;
    background-size: 100%;
    height: 200px;
  }
}

.partners-map h3 {
  padding-bottom: 15px;
  border-bottom: 1px solid $gray-light;
  margin-bottom: 20px;
  display: inline-block;
}

@media screen and (max-width: 1680px) {
  .partners-map {
    background-position-x: 80%;
  }
}

@media screen and (max-width: 1400px) {
  .partners-map {
    background-position-x: 70%;
  }
}

@media screen and (max-width: 1220px) {
  .partners-map {
    background-position-x: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .partners-map {
    height: 346px;
  }
  .partners-map h3 {
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
  .partners-map p {
    font-size: $font-size !important;
  }
}

.partners-map h3 {
  margin-top: 20px;
}

.partners-map p {
  font-size: 13px;
  color: gray;
}

ul {
  list-style: none;
  margin: 0 0 20px;
  padding-left: 30px;
}

ul > li {
  margin: 3px 0 10px;
  position: relative;
}

/*ul > li:before {
	content:"";
	position:absolute;
	width:8px;
	height:8px;
	background-color:#0961aa;
	border:1px solid #0961aa;
	left:-20px;
	border-radius:50px;
	top:7px;
}*/

/*.page54 ul > li:before {*/
/*content: "";*/
/*position: absolute;*/
/*width: 7px;*/
/*height: 7px;*/
/*background-color: #0b76ce;*/
/*border: 1px solid #0b76ce;*/
/*left: -17px;*/
/*border-radius: 50px;*/
/*top: 9px;*/
/*}*/

ul.list-important > li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}

ul.list-important > li:before {
  background-color: #0b76ce;
}

header ul li:before, footer ul li:before {
  display: none;
}

.link-red {
  display: inline-block;
  color: #C00;
  text-decoration: underline;
  margin-bottom: 12px;
}

.link-red:hover {
  color: red;
}

.text-center h2, .text-center h3, .text-center p {
  text-align: center;
}

.newsletter-box h4 {
  margin-top: 0;
  color: #484A50;
  font-weight: 700;
}

.newsletter-container {
  padding: 20px 150px;
  border-radius: 0px;
}

@media screen and (max-width: 1024px) {
  .newsletter-container {
    padding: 20px 100px;
  }
}

@media screen and (max-width: 500px) {
  .newsletter-container {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 500px) {
  .newsletter-container .btn-link {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 1034px) {
  .btn-link:after {
    display: none;
  }
}

.newsletter-container .btn-link {
  padding: 8px 30px;
}

.newsletter-box button.btn-link {
  background-color: $red-color;
  padding: 2px 20px;
  font-size: 13px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.newsletter-box button.btn-link:hover {
  background-color: $background-red;
}

.newsletter-box button.btn-link:after {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 9px solid $red-color;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.newsletter-box button.btn-link:hover:after {
  border-left: 9px solid $background-red;
}

.form-grey {
  margin-top: 60px;
}

.newsletter-info, .form-grey {
  background: url(/assets/img/project/formular_pattern.png) repeat;
  padding: 20px 40px;
}

.newsletter-info p, .form-grey p {
  font-size: 13px;
}

.newsletter-info input:not(.powermail_submit),
.newsletter-info textarea,
.form-grey input:not(.powermail_submit),
.form-grey textarea {
  background-color: $color-white;
  border: none;
  border-radius: 0;
  resize: none;
}

textarea {
  resize: vertical;
}

.newsletter-info {
  padding: 30px 40px 20px;
}

.newsletter-info .input-group {
  margin: 20px 0;
}

.newsletter-info .form-control {
  font-size: 13px;
  padding: 2px 15px;
}

/* INTERVIEW BEGIN */

.ineast-interview .col-md-7 {
  margin-top: 35px;
}


.interview-qoute h4 {
  color: #1e7ace;
  margin-top: 115px;
}

@media screen and (max-width: 500px) {
  .ineast-interview img {
    display: table;
    height: 100%;
    width: 100%;
  }
  .interview-qoute h4 {
    margin-top: 40px;
  }
}


/* RESUME BEGIN */

.resume-text {
  margin-top: 0px;
}

.resume-achievements h4 {
  padding-left: 15px;
}

.newsletter-box .input-group {
  width: 90%;
}

.newsletter-box .btn-link {
  padding: 8px 30px;
}

.newsletter-box .btn-link:after {
  content: "";
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.row-border-bottom {
  padding: 0 0 15px;
  border-bottom: 1px solid $gray-light;
}

.row-border-top {
  padding: 0;
  border-top: 1px solid $gray-light;
}

.text-with-logo-right {
  margin-top: 20px;
}

.text-with-logo-right h5 {
  margin-bottom: 5px;
  font-weight: 600;
}

.text-with-logo-right .logo-wrap img {
  width: 210px;
  height: 150px;
  margin-top: 15px;
}

.resources-contact-link h4 {
  font-size: 17px;
}

@media screen and (max-width: 768px) {
  .text-with-logo-right .logo-wrap img {
    margin-top: 45px;
  }
}

@media screen and (max-width: 420px) {
  .text-with-logo-right .logo-wrap img {
    float: none !important;
    display: block;
    margin: 0 auto;
  }
}

.clients-word {
  padding: 30px 0;
}

.text-with-icons-left {
  padding-left: 55px;
  min-height: 140px;
  margin-bottom: 30px;
}

.text-with-icons-left.developmarket {
  background: url(../img/project/logo_example.png) no-repeat;
  background-size: 60px;
  background-position: top left;
}

.text-with-icons-left h3 {
  color: #595959;
  margin: 0 0 15px;
}

@media screen and (max-width: 992px) {
  .text-with-icons-left {
    margin-bottom: 30px;
    min-height: initial;
  }
}

.benefits-quote h3 {
  font-size: 30px;
  line-height: 1.4em;
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .benefits-quote h3 {
    margin-bottom: 50px;
  }
}

.benefits-quote.partners-quote h3 {
  margin-bottom: 0;
}

.quote-with-image .quote-text {
  border-left: 1px solid $gray-light;
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .quote-with-image .quote-text {
    border: none;
  }
}

.quote-with-image .quote-text a {
  color: #D60606;
}

.quote-with-image .quote-text p, .quote-with-image .quote-text ul > li {
  font-size: $font-size;
  line-height: 1.5;
}

.quote-with-image h4 {
  color: #0b76ce;
}

.quote-with-image .quote-text ul {
  margin-left: 38px;
}

.quote-with-image .img-circle {
  margin: -34% auto 40px;
  border: 8px solid rgba(244, 244, 244, 0.8);
}

@media screen and (max-width: 990px) {
  .quote-with-image .img-circle {
    margin: 0 auto 20px;
  }
}

.quote-with-image p, .quote-with-image h4 {
  padding-left: 40px;
}

@media screen and (max-width: 420px) {
  .quote-with-image {
    margin-bottom: 60px;
  }
}

.page31 .quote-with-image {
  margin-top: 70px;
}

.one-col-with-icon {
  padding: 30px 0;
  position: relative;
}

.one-col-with-icon img {
  margin-top: 15px;
}

.one-col-with-icon.lightgrey img {
  margin-top: -20px;
}

.one-col-with-icon h2 {
  margin: 0 0 30px;
}

@media screen and (max-width: 420px) {
  .one-col-with-icon h3 {
    margin-top: 0;
  }
}

.one-col-with-icon h4 {
  color: #0b76ce;
  font-size: 17px;
  margin: 10px 0 5px;
}

.one-col-with-icon .unstyle-list ul > li:before {
  display: none;
}

.full-width-background {
  height: 280px;
}

.full-width-background h3, .full-width-background p, .full-width-background a {
  color: $color-white;
}

.full-width-background p, .full-width-background a {
  font-weight: 300;
}

@media screen and (max-width: 1365px) {
  .full-width-background {
  }
}

@media screen and (max-width: 420px) {
  .full-width-background {
    height: auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .full-width-background {
  }
}

@media screen and (max-width: 991px) {
  .full-width-background {
    background-image: none !important;
  }
}

/* CONTACT US FORM BEGIN */

input.powermail_field_error {
  border: 1px solid #c00 !important;
}

.full-width-cta {
  height: 170px;
}

.full-width-cta.lightblue {
  background-image: -webkit-linear-gradient(left, #1378d1 300px, #86bbe8 50%);
  background-image: linear-gradient(to right, #1378d1 300px, #86bbe8 50%);
}

.full-width-cta h3 {
  color: $color-white;
  margin: 0;
  padding: 70px 0;
}

.news-extension {
  border-top: 1px solid #b6d6f1;
  border-bottom: 1px solid #b6d6f1;
}

.news-menu .no-dots {
  margin: 0;
}

.form-grey .btn-link {
  background-color: $red-color !important;
  margin-bottom: 20px;
}

.form-grey .btn-link:hover, .form-grey .btn-link:active {
  background-color: $background-red !important;
}

.map-direction h4 {
  font-family: $font-head;
}

/* -------------------*/

.news-menu .no-dots li {
  margin: 8px 0;
  padding: 0 10px;
  border-right: 1px solid #b6d6f1;
  font-family: $font-pt-serif;
}

.news-menu .no-dots li:last-of-type {
  border: none;
}

.news-menu .no-dots li:before {
  display: none;
}

.news-menu .no-dots li a {
  color: #999ba4;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .news-menu .no-dots li a {
    font-size: $font-size;
  }
}

.news-menu .no-dots li a:hover, .news-menu .no-dots li a:active, .news-menu .no-dots li a:focus {
  text-decoration: underline;
  color: $text-gray;
}

.login-section > p:first-of-type {
  font-weight: 600;
  font-size: $font-size-items;
  margin-top: 50px;
}

.login-section fieldset legend, .tx-felogin-pi1 legend {
  /*
      margin-top: 10px;
      display: inline-block;
  */
  display: none;
}

.tx-felogin-pi1 h3 {
  /*
      margin-top:15px;
      margin-bottom:10px;
  */
}


.tx-felogin-pi1 form {
  margin-top: 10px;
}

.form-contact h3 {
  margin-top: 20px;
}

.form-contact .lead {
  display: inline-block;
  font-size: $font-size-items;
  margin-bottom: 10px;
}

.map-wrapper {
  margin-top: 10px;
}

.potential-partners .left-column {
  margin-top: 40px;
}

.potential-partners .left-column .btn-link {
  margin-top: 20px;
  display: inline-block;
}

.potential-partners .left-column .btn-link:after {
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

.get-involved .quote-with-image {
  margin-top: 30px;
}

@media screen and (max-width: 430px) {
  .get-involved .quote-with-image p {
    padding-left: 20px;
  }
}

.how-it-works h3 {
  margin-top: 10px;
}

@media screen and (width: 1024px) {
  .info-blue-bckg {
    padding-left: 40px;
  }
}

.partners-box-dropdown {
  border-bottom: 1px solid #ECECEC;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.partners-box-dropdown:hover .resource-headline, .partners-box-dropdown:active .resource-headline, .partners-box-dropdown:focus .resource-headline {
  color: #0b76ce;
}

.partners-box-dropdown:hover .resources-subhedline, .partners-box-dropdown:active .resources-subhedline, .partners-box-dropdown:focus .resources-subhedline {
  color: $text-gray;
}

.partners-box-dropdown .resources-subhedline {
  font-size: 18px;
  max-width: 82%;
  color: #838691;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.partners-box-dropdown .resource-headline {
  display: table;
  cursor: pointer;
  margin: 0;
  padding: 20px 0 10px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.partners-box-dropdown .plus-icon, .login-dropdown .plus-icon, .plus-icon {
  position: absolute;
  width: 34px;
  height: 34px;
  background: url(/assets/img/project/icon/plus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  transition: all .4s ease;
}

.minus-icon {
  width: 34px;
  height: 34px;
  background: url(/assets/img/project/icon/minus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .partners-box-dropdown .plus-icon, .login-dropdown .plus-icon, .plus-icon {
    display: none;
  }
}

.add-new-row {
  position: absolute;
}

.add-new-row .plus-icon {
  left: 0px;
  top: 20px;
}

#membershipTable tr:not(:first-child) .plus-icon {
  display: none;
}

#membershipTable tr > td {
  border: none;
}

.colored-bckg {
  background-color: #E9F1FA;
}

.trigger-hover {
  position: relative;
  cursor: pointer;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

@media screen and (max-width: 768px) {
  .trigger-hover {
    height: 100%;
  }
  .trigger-hover h3.resource-headline {
    font-size: 20px;
  }
  .trigger-hover p.resources-subhedline {
    font-size: $font-size-items;
    line-height: 1.4;
  }
  .partners-box-dropdown .plus-icon, .login-dropdown .plus-icon {
    right: 15px;
  }
}

@media screen and (max-width: 480px) {
  .trigger-hover p.resources-subhedline {
    line-height: 1.2;
  }
}

.trigger-hover:hover, .trigger-hover:focus, .trigger-hover:active, .active-tab {
  background-color: #E9F1FA;
}

.active-tab .resource-headline {
  color: #0b76ce
}

@media only screen and (max-width: 765px) {
  .who-we-look-for-button {
    margin-bottom: 20px;
  }
}

.partners-box-dropdown .active-tab span.plus-icon, .login-dropdown .active-tab span.plus-icon {
  background: url(/assets/img/project/icon/minus_icon_grey@2x.png) no-repeat;
  background-size: cover;
  transition: all .4s ease;
}

.trigger-in {
  display: block !important;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.partners-inner {
  display: none;
}

p.adress-icon {
  background: url(/assets/img/project/icon/home-icon@2x.png) no-repeat;
  background-position: left top;
}

p.phone-icon {
  background: url(/assets/img/project/icon/phone-icon@2x.png) no-repeat;
  background-position: left;
}

p.email-icon {
  background: url(/assets/img/project/icon/mail-icon@2x.png) no-repeat;
  background-position: left;
}

p.adress-icon, p.phone-icon, p.email-icon {
  background-size: 20px;
  padding-left: 32px;
}

.dark-bckg {
  background-image: -webkit-linear-gradient(top left, #5B5C5F, #27292e);
  background-image: linear-gradient(to bottom right, #5B5C5F, #27292e);
  padding: 10px 0 20px;
  /*position:absolute;*/
  width: 100%;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .dark-bckg {
    position: static;
  }
}

.dark-bckg p, .dark-bckg a, .dark-bckg h4, .dark-bckg span {
  color: $color-white;
  font-weight: 300;
}

.dark-bckg h4 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid;
  display: inline-block;
  min-width: 200px;
}

@media screen and (max-width: 480px) {
  .dark-bckg h4 {
    font-size: $font-size-items;
    min-width: 145px;
  }
}

.dark-bckg p, .dark-bckg li > a {
  font-size: $font-size;
}

.dark-bckg p {
  text-transform: uppercase;
  line-height: 2;
}

.dark-bckg p.lowercase {
  text-transform: none;
  line-height: 1.4;
}

.vertical-nav {
  margin-top: -5px;
}

.dark-bckg .vertical-nav li {
  margin: 5px 0;
}

.dark-bckg .vertical-nav li a {
  text-transform: uppercase;
}

.dark-bckg .vertical-nav li a:hover, .dark-bckg .vertical-nav li a:active, .dark-bckg .vertical-nav li a:focus {
  text-decoration: underline !important;
}

.dark-bckg .testimonial {
  font-size: $font-size;
  color: $text-dark-gray;
}

.dark-bckg .testimonial p {
  text-transform: none;
  color: $text-dark-gray;
}

.dark-bckg .testimonial .impressum-link, .dark-bckg .testimonial .toc-link {
  text-decoration: underline !important;
  margin-right: 10px;
  color: $text-dark-gray;
}

@media screen and (min-width: 440px) and (max-width: 769px) {
  .dark-bckg .testimonial .impressum-link, .dark-bckg .testimonial .toc-link {
    margin-right: 0;
  }
}

.dark-bckg .footer-newsletter .form-control {
  padding: 4px 15px;
  border-radius: 0;
  font-size: 13px;
  background-color: $color-white;
  border: none;
}

.dark-bckg .footer-newsletter .btn-link {
  background-color: $red-color;
  padding: 4px 20px;
  font-size: 13px;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

body.page17 .toc-link,
body.page29 .toc-link,
body.page31 .toc-link,
body.page29 .toc-link,
body.page33 .toc-link,
body.page17 .toc-link,
body.page35 .toc-link,
body.page50 .toc-link,
body.page51 .toc-link,
body.page41 .toc-link {
  display: none;
}

body.page1 .impressum-link,
body.page3 .impressum-link,
body.page4 .impressum-link,
body.page20 .impressum-link,
body.page21 .impressum-link,
body.page22 .impressum-link,
body.page24 .impressum-link,
body.page25 .impressum-link,
body.page26 .impressum-link,
body.page28 .impressum-link,
body.page37 .impressum-link,
body.page38 .impressum-link,
body.page16 .impressum-link {
  display: none;
}

.dark-bckg .footer-newsletter .btn-link:hover {
  background-color: $background-red;
}

.dark-bckg .footer-newsletter .btn-link:after {
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 8px solid $red-color;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.dark-bckg .footer-newsletter .btn-link:hover:after {
  border-left: 9px solid $background-red;
}

.dark-bckg .footer-newsletter .input-group {
  width: 90%;
}

.dark-bckg .soc-icon {
  width: 34px;
  height: 34px;
  display: inline-block;
  margin: 20px 0;
  margin-right: 6px;
}

.dark-bckg .soc-icon.facebook-icon {
  background: url(../img/project/icon/facebook_icon@2x.png) no-repeat;
  background-size: 34px;
}

.dark-bckg .soc-icon.linkedin-icon {
  background: url(../img/project/icon/linkedin_icon@2x.png) no-repeat;
  background-size: 34px;
}

ul.powermail_message.powermail_message_error {
  color: #d00;
}

/* Become a partner link : BEGIN */

.main-navigation .linkitem-li-35,
.main-navigation .linkitem-li-17,
.main-navigation .linkitem-li-92,
.become-a-partner {
  position: relative;
  display: inline-block;
  font-weight: 400;
  border: none;
  border-radius: 0;
  font-size: 15px;
  font-family: $font-pt-serif;
  box-shadow: 0 2px 3px -2px #404040;
  transition: all .4s ease;
  padding: 0;
  margin-top: 15px;
  margin-left: 20px !important;
  background: $red-color;
  font-weight: 300;
}

.main-navigation .linkitem-li-35 a,
.main-navigation .linkitem-li-92 a,
.main-navigation .linkitem-li-17 a {
  color: $color-white !important;
  padding: 5px 15px;
  font-family: $font-head;
}

.main-navigation .linkitem-li-35 a:hover,
ul.main-navigation li.linkitem-li-92 a:hover,
.main-navigation .linkitem-li-17 a:hover {
  text-decoration: none !important;
  background-color: $background-red !important;
}

@media screen and (max-width: 991px) {
  .main-navigation .linkitem-li-35,
  .main-navigation .linkitem-li-92,
  .main-navigation .linkitem-li-17 {
    margin-left: 0;
    margin: 0 auto !important;
    max-width: 180px;
    display: block;
  }
}

.become-a-partner {
  color: $color-white;
  text-decoration: none !important;
  font-family: $font-head;
  padding: 5px 15px;
  margin: 0 !important;
}

.become-a-partner:hover, .become-a-partner:focus, .become-a-partner:active {
  color: $color-white;
  background-color: $background-red;
}

.list-check, ul.powermail_message.powermail_message_error {
  padding-left: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-light;
}

.potential-partners .list-check {
  border: none;
}

.list-highlight.list-check li {
  background: url('/assets/img/project/icon/check-red.png') no-repeat;
  background-size: 21px;
  background-position-x: 7px;
  padding-left: 45px;
  text-transform: uppercase;
  font-weight: 600;
}

.list-check li:before, ul.powermail_message.powermail_message_error li:before {
  display: none;
}

.list-check-grey li {
  background: url('/assets/img/project/icon/check-grey.png') no-repeat;
  background-size: 13px;
  background-position-x: 7px;
  background-position-y: 7px;
  padding-left: 45px;

}

.testimonial {
  margin-top: 33px;
}

/* Messages Center : BEGIN */

.message-listing .table {
  margin-bottom: 0px;
  margin-top: -5px;
}

.create-new-message form .f3-form-error {
  width: 100%;
  resize: none !important;
  border: 1px solid $gray-light;
}


.message-list-single {
  padding: 20px 10px;
  margin-bottom: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.unread {
  background: #d6f1e5;
}

.message-read-button {
  text-decoration: none !important;
  color: #999;
}

.unread .message-read-button {
  text-decoration: none;
  color: #d00;
}

.message-count {
  text-decoration: none !important;
}

.message-count > span {
  margin-right: 6px;
}

.message-attachment {
  /*
      border-bottom: 1px dotted $red-color;
      margin-bottom: 30px;
  */
}

.message-attachment thead > tr > th {
  visibility: hidden;
  border-bottom: none;
  position: absolute;
  top: -99999px;
  left: -99999px;
}

.larger-col {
  width: 70%;
}

.smaller-col {
  width: 15%;
}


/* Cookies Policy Container : BEGIN */

.cc_container {
  font-size: 13px !important;
  padding: 10px 30px !important;
  font-family: 'Lato', sans-serif !important;
}

.cc_container .cc_btn {
  background-color: $red-color !important;
  color: $color-white !important;
}

.cc_more_info {
  color: #2f8ad5 !important;
}

.filter-link {
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  padding: 10px 0;
  display: block;
  border-top: 1px solid #ddd;
}

.user-filter label {
  display: block;
}

.login-nav ul {
  margin: 0 0 40px 0;
  padding: 0;
  border-top: 1px solid #ddd;
}

.login-nav ul li {
  margin: 0;
  padding: 0;
}

.login-nav ul > li:before {
  display: none;
}

.login-nav ul li a {
  text-decoration: none;
  font-size: $font-size-items;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  display: block;
  box-sizing: border-box;
  background: #fbfbfb;
}

.login-nav ul li.active a,
.login-nav ul li a:hover {

  background: #eee;
}

.toc-container input {
  vertical-align: top;
}

.toc-label {
  margin-left: 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
}

.toc-label a {
  color: inherit;
}

.social-link {
  text-decoration: none !important;
  font-size: 14px;
  /* 	text-transform: uppercase; */
  /* 	font-weight: 700; */
  display: inline-block;
}

.social-link img {
  margin-right: 10px;
}

.page58 p a {
  display: none;
}

.bluish {
  color: $text-gray;
}

.ssbutton {
  background: $red-color;
  border: 1px solid $red-color;
  padding: 5px 10px;
}

.unssbutton {
  background: $text-gray;
  color: white;
  border: 1px solid $text-gray;
  padding: 5px 10px;
}

.ssbutton,
.unssbutton {
  color: white;
  text-decoration: none !important;
}

a.ssbutton:hover,
a.unssbutton:hover {
  text-decoration: underline !important;
  color: white;
}

footer {
  min-height: 340px;
}

@media screen and (max-width: 992px) {
  footer {
    min-height: 380px;
  }
}

#map h3 {
  margin-top: 8px;
  margin-bottom: 5px;
}

/* Added HTML */
.page48 .social-link {
  margin-bottom: 10px;
}

@media screen and (min-width: 992px) {

  .references h2 {
    margin-top: 0;
    margin-bottom: 80px;
  }
  .references div[class*=col-] {
    border-right: 1px solid $background-gray;
  }

  .references div.col-md-3:hover > .references-item {
    position: relative;
    z-index: 999;
    min-height: 280px;
    box-shadow: 0 0 10px $background-gray;
    margin: -20px;
  }
  .references div.col-md-3:hover .references-item-description {
    /* 		margin: 0 20px; */
  }
  .references div.col-md-3 {
    border: 1px solid transparent;
    border-bottom: 1px solid $background-gray;
    border-right: 1px solid $background-gray;

  }

  .references div.col-md-3:nth-of-type(4n + 4) { /* remove border-right to every fourth div */
    border-right-color: transparent;
  }

  .references div.col-md-3:nth-child(n+6) { /* add border-top to all divs except first four*/
  }
}

/* END OF CE References */


/* CE:SiteSwitch */
/* container-fluid additional class CE: id=784 */
.site-switch-container {
  margin-top: 100px;
}

.site-switch {
  cursor: pointer;
  border-right: 1px solid #ECECEC;
}

.site-switch.hover {
  background-color: #0674D4;
}

/* remove padding between cols */
.site-switch-container .hidden-xs .row > div,
.site-switch-container .hidden-md .row > div {
  padding-left: 0;
  padding-right: 0;
}

.site-switch {
  border-bottom: 1px solid #ECECEC;
  background-color: #646464;
  /* 	background-color: #7da0bf; */
}

.site-switch-link {
  display: block;
}

a.site-switch-link:hover {
  text-decoration: none !important;
}

.site-switch-image {
  display: none;
  margin-bottom: 15px;
}

h3.site-switch-headline {
  margin-top: 0;
  margin-bottom: 11px;
  color: #FFFFFF;
  font-family: $font-head;
  font-size: $font-size-items !important;
  font-weight: bold;
  line-height: 17px;
}

.site-switch-inner {
  padding: 25px 15px;
  position: relative;
}

.site-switch-inner p {
  margin: 0;
}

.site-switch-content {
  color: #ECECEC;
  font-family: $font-head;
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 0;
}

.site-switch-content span.text-left {
  font-style: normal;
  color: $color-white;
  font-size: $font-size;
  line-height: 30px;
  text-decoration: underline;
}

.site-switch.active {
  position: relative;
  background-color: #0674D4;
}


.site-switch.active:after, .site-switch.active:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.site-switch.active:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #0674D4;
  border-width: 15px;
  margin-left: -15px;
}

.site-switch.active:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $color-white;
  border-width: $font-size-items;
  margin-left: -16px;
}

@media screen and (min-width: 768px) {
  .site-switch-image {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .site-switch {
    padding: 25px 50px;
  }
  .site-switch-inner {
    position: relative;
    padding-left: 130px;
  }

  .site-switch-image {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 60px;
    height: auto;
    transform: translateY(-50%);
    width: 90px;
    height: auto;
  }
}

@media screen and (min-width: 1200px) {
  .site-switch {
    padding: 15px 50px;
  }
  .site-switch-inner {
    position: relative;
    padding-left: 130px;
  }

  .site-switch-image {
    position: absolute;
    left: 15px;
    top: 50%;
    width: 60px;
    height: auto;
    transform: translateY(-50%);
    width: 90px;
    height: auto;
  }
}

/* END OF CE:SiteSwitch */


.page92 ul.list.check.list-check-grey {
  padding-left: 0;
}

.page85 .homepage-welcome-box .text-right em {
  font-size: $font-size;
}

span.admin-fielddescription.error {
  display: block;
  margin-top: 5px;
  line-height: 1.2;
  font-size: $font-size;
  color: #c70c00;
  position: relative;
  top: -5px;
}

.tooltip {
  width: 300px;
  max-width: 300px;
  opacity: 1 !important;
}

.tooltip-inner {
  max-width: none;
  background: rgba(0, 0, 0, 0.6);
}

.tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0.6) !important;
}

.small-darkicon {
  font-size: $font-size-items;
}

.form-group label, .form-group h4 {
  position: relative;
}

/* 31.05.2017 */

/* CE References */
.reference_item--image {
  background-color: $background-gray;
  width: 70%;
  max-width: 200px;
  height: auto;
  margin: 0 auto;
}

.reference_item {
  position: relative;
  background-color: $color-white;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.reference_item .reference_item--inner a,
.reference_item .reference_item--inner a:hover {
  text-decoration: none;
}


/* Title */
.reference_item--title {
  margin-bottom: 15px;
  color: #1E1F22;
  transition: all .1s ease-in-out;
}

/* Description  */
.reference_item--description {
  transition: all .1s ease-in-out;
  font-family: $font-head;
  font-size: $font-size;
  line-height: 14px;
  color: $gray-light;
  margin: 0;
}

.reference_item.hover .reference_item--description {
  display: block;
  color: $color-black;
}

/* Scale up the box */
@media only screen and (max-width: 991px) {
  .reference_item {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

  .reference_item {
    position: relative;
    width: 50%;
    margin: 25px auto;
  }
}

@media only screen and (min-width: 992px) {

  .reference_item.hover {
    height: 330px;
    margin-top: 0;
  }
  .reference_item.hover {
    margin-top: -80px;
  }
  /* Create the hidden pseudo-element */
  /* include the shadow for the end state */
  .reference_item:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s ease-in-out;
  }
  .references:after {
    content: '';
    display: block;
    position: relative;
    bottom: 1px;
    z-index: 1;
    border-top: 1px solid $color-white;
    background-color: $color-white;
    min-height: 1px;
    width: 100%;
    clear: both;
  }

  .reference_item--inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    -webkit-font-smoothing: antialiased;
    transition: all 0.2s ease-in-out;
  }
  .reference_item:hover {
    bottom: -25px;
  }


  .reference_item {
    position: relative;
    height: 250px;
  }
  .reference_item:hover {
    transform-origin: center center;
    transform: scale(1.2, 1.2);
    z-index: 3;
  }
  .reference_item:hover .reference_item--image,
  .reference_item:hover .reference_item--title,
  .reference_item:hover .reference_item--description {
    transform: scale(.9, .9);
  }
  /* Create the hidden pseudo-element */
  /* include the shadow for the end state */
  .reference_item:after {
    opacity: 0;
  }

  .reference_item--description {
    display: none;
  }
  /* Fade in the pseudo-element with the bigger shadow */
  .reference_item.hover:after {
    opacity: 1;
  }
}

/* 19.06. 2017 */
/* footer - adds crafted-by section */
.footer-crafted-by-container {
  border-top: 1px solid #585858;
  font-size: 11px;
}

.dark-bckg .footer-crafted-by .footer-crafted-by-link {
  color: $text-dark-gray;
}

.dark-bckg .footer-crafted-by .footer-crafted-by-link:hover {
  color: $color-white;
}

.footer-crafted-by-link:hover {
  text-decoration: underline !important;
}

.dark-bckg .footer-crafted-by p {
  margin-bottom: 0;
}

.footer-crafted-by-link {
  display: inline-block;
  padding: 10px 0;
  font-size: 11px;
  line-height: 15px;
  text-transform: none;
}

.dark-bckg .footer-crafted-by {
  font-size: 11px;
  line-height: 15px;
}

/* 2017-30-06 */
/* Cookies container */
div.cc_container {
  background: rgba(34, 34, 34, .9);
}

.dark-bckg {
  padding-bottom: 50px;
  -webkit-transition: padding-bottom .4s ease;
  transition: padding-bottom .4s ease;
}